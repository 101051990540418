export enum TerritorialCitizenRegisterStatusEnum {
	FCI_UPDATED_AND_FCDT_OUTDATED = 1,
	FCI_OUTDATED_AND_FCDT_NOT_REGISTERED = 2,
	FCI_AND_FCDT_OUTDATED = 3,
	FCI_AND_FCDT_UPDATED = 4,
	FCI_OUTDATED_AND_FCDT_UPDATED = 5,
	FCDT_UPDATED_WITHOUT_FCI = 6,
	FCDT_OUTDATED_WITHOUT_FCI = 7,
	WITHOUT_FCI_AND_FCDT = 8,
	FCI_UPDATED_AND_FCDT_NOT_REGISTERED = 9
}
