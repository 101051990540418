import { FaInfoCircle } from "react-icons/fa";
import OverlayTrigger, {
	OverlayTriggerProps,
	OverlayTriggerType,
} from "react-bootstrap/OverlayTrigger";

import { Placement } from "@popperjs/core";

import Popover from "react-bootstrap/Popover";

import "./styles.scss";

type Props = {
	header?: string;
	message?: string;
	icon?: React.ReactNode;
	children?: React.ReactNode;
	placement?: Placement;
	trigger?: OverlayTriggerType[];
};

export function CardOverlayTrigger({
	header,
	message,
	placement,
	icon,
	children,
	trigger,
	...rest
}: Props) {
	const overlayTriggerProps: OverlayTriggerProps = {
		overlay: (
			<Popover className="custom-popover">
				<Popover.Header as="h3">{header}</Popover.Header>
				<Popover.Body className="fs-8 gap-2">
					<div>{message}</div>
					<div className="text-center">{children}</div>
				</Popover.Body>
			</Popover>
		),
		trigger: trigger ?? ["hover", "click"],
		placement: placement ?? "bottom",
		rootClose: true,
		children: <span>{icon ?? <FaInfoCircle />}</span>,
	};

	return <OverlayTrigger {...overlayTriggerProps} />;
}
