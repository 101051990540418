import { get } from "../api";

type Params = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export async function getMicroAreas({ uf, ibgeCode, ...params }: Params) {
	const response = await get<string[]>(
		`/uf/${uf}/city/${ibgeCode}/Citizen/GetMicroAreas`,
		params
	);
	return response.data || [];
}
