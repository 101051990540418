import { useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { BasePatient } from "@/@types/BasePatient";
import { Paginated } from "@/@types/generics/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator7 } from "../ModalIndicator7";

import "./styles.scss";

type Props = {
	patient: Paginated<BasePatient>;
	isLoading: boolean;
};

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	name: Column;
	birthdate: Column;
	identifier: Column;
	healthTeam: Column;
	mc: Column;
	selfReported: Column;
	evaluatedCondition: Column;
	lastAttendance: Column;
	lastRequest: Column;
	nextEvaluation: Column;
};

export function TableIndicator7({ isLoading, patient }: Props) {
	const [patientSelected, setPatientSelected] = useState<BasePatient>({} as BasePatient);
	const [showModalIndicator7, setShowModalIndicator7] = useState(false);
	const handleShowModalIndicator7 = () => {
		setShowModalIndicator7(true);
	};
	const handleCloseModalIndicator7 = () => {
		setShowModalIndicator7(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		name: { title: "Nome", visible: true },
		birthdate: { title: "DN", visible: true },
		identifier: { title: "CPF/CNS", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		mc: { title: "MC", visible: false },
		selfReported: { title: "Autorreferido", visible: false },
		evaluatedCondition: { title: "Condição avaliada", visible: true },
		lastAttendance: { title: "Última consulta", visible: false },
		lastRequest: { title: "Última solicitação", visible: true },
		nextEvaluation: { title: "Próxima avaliação", visible: true },
	});

	const handleColumnToggle = (column: keyof ColumnsTable) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[column]: {
				...prevColumns[column],
				visible: !prevColumns[column].visible,
			},
		}));
	};

	return (
		<div>
			<ModalIndicator7
				show={showModalIndicator7}
				onHide={handleCloseModalIndicator7}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						{columns.name.visible && <th>CIDADÃO</th>}
						{columns.birthdate.visible && <th>DN</th>}
						{columns.identifier.visible && <th>CPF/CNS</th>}
						{columns.healthTeam.visible && <th>EQUIPE</th>}
						{columns.mc.visible && <th>MC</th>}
						{columns.selfReported.visible && <th>AUTORREFERIDO </th>}
						{columns.evaluatedCondition.visible && <th>CONDIÇÃO AVALIADA</th>}
						{columns.lastAttendance.visible && <th>ULTIMA CONSULTA</th>}
						{columns.lastRequest.visible && <th>ULTIMA SOLICITAÇÃO</th>}
						{columns.nextEvaluation.visible && <th>PRÓXIMA AVALIAÇÃO</th>}
						<th className="d-flex align-items-baseline justify-content-between text-nowrap pb-0 no-print no-excel">
							<label>AÇÕES</label>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && patient?.data ? (
						patient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								{columns.name.visible && (
									<td title="nome" className="text-nowrap">
										{patient.fullName || "N/A"}
									</td>
								)}
								{columns.birthdate.visible && (
									<td className="text-nowrap text-center">
										{patient.birthdate
											? dayjs(patient.birthdate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								{columns.identifier.visible && (
									<td title="cpf" className="text-nowrap text-center">
										{identifierOrNationalHealthCard(patient)}
									</td>
								)}
								{columns.healthTeam.visible && (
									<td title="equipe" className="text-nowrap text-center">
										{patient.healthTeam?.name || "N/A"}
									</td>
								)}
								{columns.mc.visible && (
									<td title="Micro Área" className="text-nowrap text-center">
										{patient.area || "N/A"}
									</td>
								)}
								{columns.selfReported.visible && (
									<td title="autorreferido" className="text-nowrap text-center">
										{patient.cardiacRisk?.haveDiabetesCds ? "SIM" : "NÃO"}
									</td>
								)}
								{columns.evaluatedCondition.visible && (
									<td title="condição avaliada" className="text-center">
										{patient.attendances && patient.attendances?.length > 0
											? "SIM"
											: "NÃO"}
									</td>
								)}
								{columns.lastAttendance.visible && (
									<td title="ultima consulta" className="text-nowrap text-center">
										{patient.attendances && patient.attendances[0]?.date
											? dayjs(patient.attendances[0]?.date).format(
												"DD/MM/YYYY"
											)
											: "N/A"}

									</td>
								)}
								{columns.lastRequest.visible && (
									<td
										title="ultima solicitação"
										className="text-nowrap text-center"
									>
										{patient.attendanceProceduresReqEvals &&
											patient.attendanceProceduresReqEvals[0]?.date
											? dayjs(
												patient.attendanceProceduresReqEvals[0]?.date
											).format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								{columns.nextEvaluation.visible && (
									<td title="Próxima avaliação" className="text-center">
										{patient.attendanceProceduresReqEvals?.at(0)?.date
											? dayjs(patient.attendanceProceduresReqEvals?.at(0)?.date).add(6, "month").format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								<td className="text-center no-print">
									<ViewCitizenButton
										onClick={() => {
											setPatientSelected(patient);
											handleShowModalIndicator7();
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons
							numberOfCells={
								Object.entries(columns).filter((a) => a[1]?.visible === true)
									.length + 1
							}
							height={20}
							numberOfRows={10}
						/>
					)}
				</tbody>
			</Table>
		</div>
	);
}
