import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsClipboardData } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useHealthTeams } from "@/hooks/useHealthTeam";
import { Option } from "@/@types/generics/Option";
import {
	GetTerritorialCitizensAccompanimentResume,
	TerritorialCitizenAccompanimentResume,
} from "@/services/esus/territorialCitizenService";
import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";
import { ResumeGrouped } from "@/services/esus/attendanceService";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { VulnerableCitizenEnum } from "@/utils/enums/VulnerableCitizenEnum";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { GetCityPopulationClassification } from "@/services/app/cityService";
import {
	CityPopulationClassification,
	citiesPopulationsClassifications,
} from "@/utils/cityPopulationClasssification";

import { AccompanimentDimensionCards } from "./AccompanimentDimensionCards";
import { DonutChart } from "@/components/Charts/DonutChart";
import { PieChart } from "@/components/Charts/PieChart";
import { TeamPerformanceCharts } from "../TeamPerformanceCharts";
import { ChartAnnotation } from "@/components/Charts/BarChart";
import { QuartersSelect } from "@/components/QuartersSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { LoadingScreen } from "@/components/LoadingScreen";

type SeriesData = {
	punishment: string;
	classification: string;
	name: string;
	data: number[];
};

export function AccompanimentDimension() {
	const { healthTeams } = useHealthTeams();
	const { ibgeCode, uf, cnes, currentAccount } = useCurrentAccount();
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>();
	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<Option<string>>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});

	const [isLoadingCitizenAccompanimentResume, setIsLoadingCitizenAccompanimentResume] =
		useState(false);
	const [isLoadingCityClassificationHistory, setIsLoadingCityClassificationHistory] =
		useState(false);
	const [
		keyMenuPerformanceAccompanimentHealthTeam,
		setKeyMenuPerformanceAccompanimentHealthTeam,
	] = useState<HealthTeamTypeEnum | null>(HealthTeamTypeEnum.ESF);

	const [territorialCitizenAccompanimentResume, setTerritorialCitizenAccompanimentResume] =
		useState<TerritorialCitizenAccompanimentResume>();

	const [cityPopulationClassification, setCityPopulationClassification] = useState<
		CityPopulationClassification | undefined
	>();

	const healthTeamTypeParams = [
		{
			ms: cityPopulationClassification?.parameter.esf.code,
			min: cityPopulationClassification?.parameter.esf.min,
			max: cityPopulationClassification?.parameter.esf.max,
		},
		{
			ms: cityPopulationClassification?.parameter.eap20.code,
			min: cityPopulationClassification?.parameter.eap20.min,
			max: cityPopulationClassification?.parameter.eap20.max,
		},
	];

	const healthTeamTypeParamSelected =
		healthTeamTypeParams.find(
			(healthTeamTypeParam) =>
				healthTeamTypeParam.ms === keyMenuPerformanceAccompanimentHealthTeam
		) || healthTeamTypeParams[0];

	const chartAnnotations: ChartAnnotation[] = [
		{
			value: healthTeamTypeParamSelected.min || 0,
			lineColor: "#ffc700",
			color: "#ffc700",
			backgroundColor: "#FFFFFF",
			text: `Parâmetro: ${healthTeamTypeParamSelected.min?.toLocaleString()}`,
		},
		{
			value: healthTeamTypeParamSelected.max || 0,
			lineColor: "#FF4E79",
			color: "#FF4E79",
			backgroundColor: "#FFFFFF",
			text: `Teto: ${healthTeamTypeParamSelected.max?.toLocaleString()}`,
		},
	];

	function filterByVulnerability(
		territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume: ResumeGrouped<
			HealthTeam,
			ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>
		>[],
		isAccompaniment: boolean,
		key: keyof typeof VulnerableCitizenEnum
	) {
		const vulnerableSeries = (
			territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume || []
		).flatMap(
			(groupedHealthTeam) =>
				groupedHealthTeam.values
					?.filter((groupedAccompaniment) => groupedAccompaniment.key === isAccompaniment)
					.map(
						(groupedVulnerableStatus) =>
							groupedVulnerableStatus.values?.find(
								(vulnerableStatus) =>
									vulnerableStatus.key === VulnerableCitizenEnum[key]
							)?.total || 0
					) || []
		);

		return vulnerableSeries;
	}

	function vulnerabilityNotAccompaniedByHealthteamSeries(
		territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume: ResumeGrouped<
			HealthTeam,
			ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>
		>[]
	) {
		const totalsByGroup =
			territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume.reduce(
				(acc, healthTeam) => {
					const total = (healthTeam.values || []).reduce((sum, groupedAccompaniment) => {
						if (groupedAccompaniment.key === false) {
							return (
								sum +
								(groupedAccompaniment.values || []).reduce(
									(acc, status) => acc + (status.total || 0),
									0
								)
							);
						}
						return sum;
					}, 0);

					acc[healthTeam.key.name] = total;
					return acc;
				},
				{} as Record<string, number>
			);

		const healthTeamNames =
			territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume.map(
				(healthTeam) => healthTeam.key.name
			);
		const resultSeries = healthTeamNames.map(
			(healthTeamName) => totalsByGroup[healthTeamName] || 0
		);

		return {
			categories: healthTeamNames,
			series: resultSeries,
		};
	}

	function getResumeBySelectedHealthTeamType() {
		switch (keyMenuPerformanceAccompanimentHealthTeam) {
			case HealthTeamTypeEnum.ESF:
				return (
					territorialCitizenAccompanimentResume?.territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamESFResume ||
					[]
				);
			case HealthTeamTypeEnum.EAP:
				return (
					territorialCitizenAccompanimentResume?.territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamEAPResume ||
					[]
				);
			default:
				return [];
		}
	}

	const noCriteriaSeries = filterByVulnerability(
		getResumeBySelectedHealthTeamType(),
		true,
		"NO_CRITERION"
	);

	const oldPeopleAndChildrenSeries = filterByVulnerability(
		getResumeBySelectedHealthTeamType(),
		true,
		"OLD_PEOPLE_AND_CHILDREN"
	);

	const citizensReceivingSocialBenefitsSeries = filterByVulnerability(
		getResumeBySelectedHealthTeamType(),
		true,
		"CITIZENS_RECEIVING_SOCIAL_BENEFITS"
	);

	const bothCriteriaSeries = filterByVulnerability(
		getResumeBySelectedHealthTeamType(),
		true,
		"BOTH_CRITERIA"
	);

	const unaccompaniedWithoutJudgment = vulnerabilityNotAccompaniedByHealthteamSeries(
		getResumeBySelectedHealthTeamType()
	).series;

	const categoriesChart = getResumeBySelectedHealthTeamType().map(
		(groupedHealthTeam) => groupedHealthTeam.key.name
	);

	const seriesChart = [
		{
			data: noCriteriaSeries,
			classification: "",
			punishment: "",
			name: "Sem critério",
		},
		{
			data: oldPeopleAndChildrenSeries,
			classification: "",
			punishment: "",
			name: "Idoso ou criança",
		},
		{
			data: citizensReceivingSocialBenefitsSeries,
			classification: "",
			punishment: "",
			name: "PBF ou BPC",
		},
		{
			data: bothCriteriaSeries,
			classification: "",
			punishment: "",
			name: "Ambos os critérios",
		},
		{
			data: unaccompaniedWithoutJudgment,
			classification: "",
			punishment: "",
			name: "Não acompanhado",
		},
	] as SeriesData[];

	async function handleTerritorialCitizensAccompanimentResume() {
		return await GetTerritorialCitizensAccompanimentResume({
			ibgeCode: ibgeCode,
			uf: uf,
			quarter: selectedQuarter.value,
			ine: selectedHealthTeam?.value,
			cnes: cnes,
		});
	}

	async function fetchAccompanimentResume() {
		const cacheKey = `[accompaniment-dimension-dashboard][${uf}][${ibgeCode}][${cnes}][${selectedQuarter.value}][${selectedHealthTeam?.value}]`;
		setIsLoadingCitizenAccompanimentResume(true);
		setTerritorialCitizenAccompanimentResume(
			await fetchWithCache(
				cacheKey,
				getExpirationDateMidnight(),
				handleTerritorialCitizensAccompanimentResume
			)
		);
		setIsLoadingCitizenAccompanimentResume(false);
	}

	async function handleCityPopulationClassification() {
		const cityPopulactionClassification = await GetCityPopulationClassification({
			cityId: currentAccount.id,
			quarter: selectedQuarter.value,
		});
		return citiesPopulationsClassifications.find(
			(city) =>
				city.cityPopulationClassification ===
				cityPopulactionClassification.cityPopulationClassificationId
		);
	}

	async function fetchSizeHistory() {
		setIsLoadingCityClassificationHistory(true);
		setCityPopulationClassification(await handleCityPopulationClassification());
		setIsLoadingCityClassificationHistory(false);
	}

	useEffect(() => {
		fetchSizeHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuarter]);

	useEffect(() => {
		fetchAccompanimentResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoadingScreen loading={isLoadingCitizenAccompanimentResume} />
			<Row className="bg-white d-flex justify-content-between mx-3 p-3">
				<Col sm={12} md={3} lg={3} xl={3}>
					<QuartersSelect
						value={selectedQuarter}
						onChange={(newValue) => setSelectedQuarter(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={12} md={4} lg={4} xl={4}>
					<HealthTeamsSelect
						healthTeams={healthTeams}
						value={selectedHealthTeam}
						onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={2} md={2} lg={2} xl={2} className="d-flex mt-2 align-items-end">
					<Button
						onClick={() => {
							fetchAccompanimentResume();
						}}
					>
						Consultar
					</Button>
				</Col>
			</Row>
			<Row className="m-3">
				<Col className="p-0">
					<Card>
						<Card.Header className="d-flex justify-content-between ps-2">
							<Card.Title className="text-primary rounded gap-2">
								<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
									<BsClipboardData />
								</label>
								Dimensão acompanhamento
							</Card.Title>
							{!isLoadingCityClassificationHistory &&
								cityPopulationClassification?.cityPopulationClassification && (
									<Card.Title>
										<strong>Porte do municipio:</strong>
										<div className="ms-2">
											{`${cityPopulationClassification?.cityPopulationClassification} (${cityPopulationClassification?.description})`}
										</div>
									</Card.Title>
								)}
						</Card.Header>
					</Card>
				</Col>
			</Row>
			<AccompanimentDimensionCards
				territorialCitizenAccompanimentResume={territorialCitizenAccompanimentResume}
			/>
			<Row className="m-3">
				<Col sm={12} md={6} lg={6} xl={6} className="mt-2">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>Acompanhamentos</Card.Title>
						</Card.Header>
						<Card.Body className="h-100 w-100 d-flex justify-content-center">
							<DonutChart
								labels={["Financiados", "Pendentes"]}
								colors={["#00E195", "#FFD52F"]}
								series={[
									territorialCitizenAccompanimentResume?.accompanimentResume
										?.totalAccompanied || 0,
									(territorialCitizenAccompanimentResume?.accompanimentResume
										?.total || 0) -
										(territorialCitizenAccompanimentResume?.accompanimentResume
											?.totalAccompanied || 0) -
										(territorialCitizenAccompanimentResume?.accompanimentResume
											?.totalFastRegister || 0),
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={6} lg={6} xl={6} className="mt-2">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>Total de classificações por acompanhamento</Card.Title>
						</Card.Header>
						<Card.Body className="h-100 w-100">
							<PieChart
								categories={[
									"Sem critérios",
									"Idoso ou criança",
									"PBF ou BPC",
									"Ambos os critérios",
								]}
								series={[
									territorialCitizenAccompanimentResume
										?.classificatonByAccompanimentResume
										?.noCriteriaSeriesTotal || 0,
									territorialCitizenAccompanimentResume
										?.classificatonByAccompanimentResume
										?.oldPeopleAndChildrenSeriesTotal || 0,
									territorialCitizenAccompanimentResume
										?.classificatonByAccompanimentResume
										?.citizensReceivingSocialBenefitsSeriesTotal || 0,
									territorialCitizenAccompanimentResume
										?.classificatonByAccompanimentResume
										?.bothCriteriaSeriesTotal || 0,
								]}
								height={200}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="m-3">
				<TeamPerformanceCharts
					title="Desempenho"
					keyMenu={String(keyMenuPerformanceAccompanimentHealthTeam)}
					setKeyMenu={(value) => {
						setKeyMenuPerformanceAccompanimentHealthTeam(Number(value));
					}}
					data={{
						series: seriesChart,
						categories: categoriesChart,
						annotations: chartAnnotations,
					}}
					isLoading={isLoadingCitizenAccompanimentResume}
					chartColors={["#009EF7", "#00E296", "#FFD83F", "#F1416C", "#A3A5A5"]}
				/>
			</Row>
		</div>
	);
}
