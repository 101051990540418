import { Props as SelectProps } from "react-select";

import { Option } from "@/@types/generics/Option";
import { TerritorialCitizenRegisterStatusEnum } from "@/utils/enums/TerritorialCitizenRegisterStatus";

import { SelectComponent } from "../SelectComponent";

type Props = SelectProps;

type RegistrationStatus = {
	name: string;
	value: number | null;
	isFciFinanced: boolean;
	isFcdtFinanced: boolean;
};

const territorialRegistrationStatus: RegistrationStatus[] = [
	{
		name: "FCI atualizado e FCDT atualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED,
		isFciFinanced: true,
		isFcdtFinanced: true,
	},
	{
		name: "FCI atualizado e FCDT desatualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED,
		isFciFinanced: true,
		isFcdtFinanced: false,
	},
	{
		name: "FCI atualizado e FCDT sem cadastro",
		value: TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED,
		isFciFinanced: true,
		isFcdtFinanced: false,
	},
	{
		name: "FCI desatualizado e FCDT atualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_UPDATED,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
	{
		name: "FCI desatualizado e FCDT desatualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_OUTDATED,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
	{
		name: "FCI desatualizado e FCDT sem cadastro",
		value: TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_NOT_REGISTERED,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
	{
		name: "FCI sem cadastro e FCDT atualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCDT_UPDATED_WITHOUT_FCI,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
	{
		name: "FCI sem cadastro e FCDT desatualizado",
		value: TerritorialCitizenRegisterStatusEnum.FCDT_OUTDATED_WITHOUT_FCI,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
	{
		name: "FCI e FCDT sem cadastro ( cadastro rápido )",
		value: TerritorialCitizenRegisterStatusEnum.WITHOUT_FCI_AND_FCDT,
		isFciFinanced: false,
		isFcdtFinanced: false,
	},
];

export function TerritorialRegistrationStatusSelect({ ...rest }: Props) {
	const options: Option<number | null>[] = territorialRegistrationStatus.map((status) => {
		return {
			value: status.value,
			label: status.name,
		};
	});

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id} className="form-label">
				Situação de cadastro:
			</label>
			<SelectComponent
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum quadrimestre encontrado"}
				options={options}
				{...rest}
			/>
		</div>
	);
}
