import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { TerritorialCitizenAccompanimentResume } from "@/services/esus/territorialCitizenService";

import { CardInformative } from "@/pages/esus/ReterritorializationAcsDashboard/CardInformative";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

type Props = {
	territorialCitizenAccompanimentResume?: TerritorialCitizenAccompanimentResume;
};

export function AccompanimentDimensionCards({ territorialCitizenAccompanimentResume }: Props) {
	return (
		<Row className="bg-white m-3 p-2 justify-content-evenly rounded">
			<Col sm={6} md={6} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Cidadãos identificados"
					value={
						territorialCitizenAccompanimentResume?.accompanimentResume?.total?.toLocaleString() ??
						0
					}
					classNameCard="rounded p-2 m-2 bg-primary h-100"
					classNameTitle="text-white text-center"
					classNameValue="text-white"
					metaData={
						<CardOverlayTrigger
							header="Cidadãos Identificados"
							message="Cidadãos cadastrados nos últimos 24 meses, considerando também aqueles sem ficha de cadastro (individual, domiciliar ou territorial), estejam atualizados ou não."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={6} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="2 ou mais acompanhamentos"
					value={
						territorialCitizenAccompanimentResume?.accompanimentResume?.totalPeopleWithTwoOrMoreContacts?.toLocaleString() ??
						0
					}
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					metaData={
						<CardOverlayTrigger
							header="2 ou mais acompanhamentos"
							message="N° de cidadãos com 2 ou mais contatos assistenciais"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={6} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="1 acompanhamento"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={
						territorialCitizenAccompanimentResume?.accompanimentResume?.totalPeopleWithOneContact?.toLocaleString() ??
						0
					}
					metaData={
						<CardOverlayTrigger
							header="1 acompanhamento"
							message="N° de cidadãos com 1 contato assistencial"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={6} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Sem nenhum acompanhamento"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={
						territorialCitizenAccompanimentResume?.accompanimentResume?.totalPeopleWithNoContacts?.toLocaleString() ??
						0
					}
					metaData={
						<CardOverlayTrigger
							header="Sem nenhum acompanhamento"
							message="N° de cidadãos com nenhum contato assistencial"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={6} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Cadastros rápidos"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={
						territorialCitizenAccompanimentResume?.accompanimentResume?.totalFastRegister?.toLocaleString() ??
						0
					}
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros que não serão considerados para cálculo do financiamento"
							message="Nº de Cadastros que não possuem ficha de cadastro individual ou ficha de cadastro domiciliar e territorial"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
