import Select, { Props } from "react-select";
import makeAnimated from "react-select/animated";

export function SelectComponent({ ...props }: Props) {
	const animatedComponents = makeAnimated();
	return (
		<Select
			components={animatedComponents}
			styles={{
				menu: (base) => ({ ...base, zIndex: 90 }),
				control: (base) => ({
					...base,
					minHeight: "30px",
					padding: "0px",
				}),
				valueContainer: (base) => ({
					...base,
					padding: "0px 4px",
				}),
				indicatorsContainer: (base) => ({
					...base,
					height: "30px",
				}),
			}}
			{...props}
		/>
	);
}
