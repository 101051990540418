import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import { Chart } from "@/@types/generics/Chart";

type RadialChartSerie = number;
type RadialChartProps = Chart<RadialChartSerie> & {
	legendPosition?: "top" | "right" | "bottom" | "left";
	series: number[];
	labels: string[];
	colors?: string[];
	textLabel?: string;
};

export function DonutChart({
	series,
	labels,
	height,
	width,
	colors,
	legendPosition,
	textLabel,
}: RadialChartProps) {
	const options: ApexOptions = {
		dataLabels: {
			enabled: true,
		},
		plotOptions: {
			pie: {
				donut: {
					labels: {
						name: {
							formatter(val) {
								return textLabel ?? val;
							},
						},
						show: true,
						total: {
							formatter(w) {
								return w.globals.seriesTotals
									.reduce((a: number, b: number) => {
										return a + b;
									}, 0)
									.toLocaleString();
							},
							showAlways: true,
							show: true,
						},
						value: {
							formatter(val) {
								return Number(val).toLocaleString();
							},
						},
					},
				},
			},
		},
		tooltip: {
			y: {
				formatter: (value) => {
					return value.toLocaleString();
				},
			},
		},
		colors: colors,
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200,
					},
					legend: {
						show: false,
					},
				},
			},
		],
		labels: labels,
		legend: {
			position: legendPosition ?? "bottom",
			offsetY: 0,
			height: 0,
		},
	};
	return (
		<ReactApexChart
			options={options}
			series={series}
			type="donut"
			height={height}
			width={width}
		/>
	);
}
