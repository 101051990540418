import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardInformative } from "@/pages/esus/ReterritorializationAcsDashboard/CardInformative";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

type Props = {
	totalPaymentByEsf: number;
	totalPaymentByEAP: number;
	totalPaymentAchievable: number;
	totalPayment: number;
};

export function ResourceEvaluationDimensionCards({
	totalPaymentAchievable,
	totalPaymentByEAP,
	totalPaymentByEsf,
	totalPayment,
}: Props) {
	return (
		<Row className="justify-content-evenly rounded bg-white m-2 p-2">
			<Col sm={6} md={4} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Previsão valor ESF"
					value={`R$ ${totalPaymentByEsf.toLocaleString()}`}
					classNameCard="border border-muted bg-white rounded p-2 m-2 h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					metaData={
						<CardOverlayTrigger
							header="Previsão valor ESF"
							message="Esse valor representa a soma de recursos previstos de todas as equipes ESF"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Previsão valor EAP"
					classNameCard="border border-muted bg-white rounded p-2 m-2 h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={`R$ ${totalPaymentByEAP.toLocaleString()}`}
					metaData={
						<CardOverlayTrigger
							header="Previsão valor EAP"
							message="Esse valor representa a soma de recursos previstos de todas as equipes EAP"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Previsão valor alcançavel"
					classNameCard="border border-muted bg-white rounded p-2 m-2 h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={`R$ ${totalPaymentAchievable.toLocaleString()}`}
					metaData={
						<CardOverlayTrigger
							header="Previsão valor alcançavel"
							message="Esse valor representa a soma de recursos máximos de todas as equipes"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Total previsto"
					classNameCard="bg-success rounded p-2 my-2 h-100"
					classNameTitle="text-white text-center"
					classNameValue="text-white"
					value={`R$ ${totalPayment.toLocaleString()}`}
					metaData={
						<CardOverlayTrigger
							header="Total previsto"
							message="Esse valor representa a soma de recursos atingidos por todas as equipes"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
