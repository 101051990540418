import { Props as SelectProps } from "react-select";

import { Option } from "@/@types/generics/Option";
import { VulnerableCitizenEnum } from "@/utils/enums/VulnerableCitizenEnum";

import { SelectComponent } from "../SelectComponent";

type Props = SelectProps;

type RegistrationStatus = {
	label: string;
	value: number | null;
};

const territorialRegistrationStatus: RegistrationStatus[] = [
	{ label: "Todos", value: null },
	{ label: "Idoso ou criança", value: VulnerableCitizenEnum.OLD_PEOPLE_AND_CHILDREN },
	{ label: "Beneficiário", value: VulnerableCitizenEnum.CITIZENS_RECEIVING_SOCIAL_BENEFITS },
	{ label: "Ambos os critérios", value: VulnerableCitizenEnum.BOTH_CRITERIA },
];

export function VulnerableCitizenSelect({ ...rest }: Props) {
	const options: Option<number | null>[] = territorialRegistrationStatus.map((status) => {
		return {
			value: status.value,
			label: status.label,
		};
	});

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id} className="form-label">
				Vulnerabilidade:
			</label>
			<SelectComponent
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum quadrimestre encontrado"}
				options={options}
				{...rest}
			/>
		</div>
	);
}
