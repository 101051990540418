import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { CardInformative } from "@/pages/esus/ReterritorializationAcsDashboard/CardInformative";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

type Props = {
	totalCitizenIdentified: number;
	totalFciAndFcdtFinanced: number;
	totalFciFinanced: number;
	totalFciNotFinanced: number;
	totalFastRegistrations: number;
	totalFciNotRegistered: number;
};

export function RegistrationDimensionCards({
	totalCitizenIdentified,
	totalFciAndFcdtFinanced,
	totalFciFinanced,
	totalFciNotFinanced,
	totalFastRegistrations,
	totalFciNotRegistered,
}: Props) {
	return (
		<Row className="m-3 p-2 bg-white justify-content-evenly rounded">
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Cidadãos identificados"
					value={totalCitizenIdentified.toLocaleString()}
					classNameCard="m-2 rounded p-2 bg-primary h-100"
					classNameTitle="text-white text-center"
					classNameValue="text-white"
					metaData={
						<CardOverlayTrigger
							header="N° de cidadãos identificados"
							message="Cidadãos cadastrados nos últimos 24 meses, considerando também aqueles sem ficha de cadastro (individual, domiciliar ou territorial), estejam atualizados ou não."
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="FCI + FCDT financiadas"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					value={totalFciAndFcdtFinanced.toLocaleString()}
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros completos atualizados"
							message="Cidadãos que estão com os cadastros individual e territorial, ambos atualizados"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Somente FCI financiadas"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={totalFciFinanced.toLocaleString()}
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros individuais atualizados"
							message="Cidadãos que estão somente com o cadastro individual atualizado"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Não financiados"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={totalFciNotFinanced.toLocaleString()}
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros individuais não financiados"
							message="Cidadãos com cadastro individual desatualizado"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="FCI não cadastrado"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={totalFciNotRegistered.toLocaleString()}
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros individuais não financiados"
							message="Cidadãos sem FCI ou que estão com FCDT desatualizado"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
			<Col sm={12} md={5} lg={2} xxl={2} className="my-2">
				<CardInformative
					title="Cadastros rápidos"
					classNameCard="border border-muted rounded p-2 m-2 bg-white h-100"
					classNameTitle="text-muted text-center"
					classNameValue="text-muted"
					value={totalFastRegistrations.toLocaleString()}
					metaData={
						<CardOverlayTrigger
							header="N° de cadastros que não serão considerados para cálculo do financiamento"
							message="Nº de Cadastros que não possuem ficha de cadastro individual ou ficha de cadastro domiciliar e territorial"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
