import { get } from "../api";
import { Indicators } from "@/utils/enums/indicators";
import { IndicatorType } from "@/utils/enums/indicatorType";
import { Paginated } from "@/@types/generics/paginated";
import { BasePatient } from "@/@types/BasePatient";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { Attendance } from "@/@types/esus/Attendance";
import { DentalAttendance } from "@/@types/esus/DentalAttendance";

export type IndicatorResume = {
	valid: number;
	pendingEligible: number;
	pendingIneligible: number;
};

export type IndicatorData = {
	Identified: number;
	Valid: number;
};

export type PregnantPatient = BasePatient & {
	socialName: string;
	firstDumDate: string;
	firstAttendanceDate: string;
	lastAttendanceDate: string;
	dppDate: string;
	gender: number;
	gestationalAge1St: number;
	inPreliminaryReport: boolean;
	attendances: Attendance[];
	dentalAttendances?: DentalAttendance[];
	preliminaryReportStatus: number;
	phone?: string;
};

export type IndicatorsData = {
	indicatorOne: IndicatorData[];
	indicatorTwo: IndicatorData[];
	indicatorThree: IndicatorData[];
	indicatorFour: IndicatorData[];
	indicatorFive: IndicatorData[];
	indicatorSix: IndicatorData[];
	indicatorSeven: IndicatorData[];
};

type IndicatorDetails = {
	healthTeam: HealthTeam;
	Identified: number;
	Valid: number;
};

export type IndicatorsHealthTeam = {
	indicatorOne: IndicatorDetails[];
	indicatorTwo: IndicatorDetails[];
	indicatorThree: IndicatorDetails[];
	indicatorFour: IndicatorDetails[];
	indicatorFive: IndicatorDetails[];
	indicatorSix: IndicatorDetails[];
	indicatorSeven: IndicatorDetails[];
};

type IndicatorParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	indicator: Indicators;
	indicatorType: IndicatorType | null;
	cnes: string;
	ine: string;
	searchBirthDateCitizen: string;
	searchCitizenFullName: string;
	quarter: string;
	pageSize?: number;
};

type IndicatorResumeParams = {
	indicator: Indicators;
	quarter: string;
	uf: string;
	ibgeCode: string;
	cnes: string;
	ine: string;
};

type IndicatorsGroupedByHealthTeamParams = {
	uf: string;
	ibgeCode: string;
	quarter: string;
	cnes?: string;
	ine?: string;
};

export async function handleIndicator1(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 1,
		}
	);
	return response.data || ({} as Paginated<PregnantPatient>);
}

export async function handleIndicator2(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 2,
		}
	);
	return response.data || ({} as Paginated<PregnantPatient>);
}

export async function handleIndicator3(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<PregnantPatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 3,
		}
	);
	return response.data || ({} as Paginated<PregnantPatient>);
}

export async function handleIndicator4(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 4,
		}
	);
	return response.data || ({} as Paginated<BasePatient>);
}

export async function handleIndicator5(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 5,
		}
	);
	return response.data || ({} as Paginated<BasePatient>);
}

export async function handleIndicator6(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 6,
		}
	);
	return response.data || ({} as Paginated<BasePatient>);
}

export async function handleIndicator7(indicatorParams: Omit<IndicatorParams, "indicator">) {
	const response = await get<Paginated<BasePatient>>(
		`/uf/${indicatorParams.uf}/city/${indicatorParams.ibgeCode}/indicator/getindicator`,
		{
			...indicatorParams,
			indicator: 7,
		}
	);
	return response.data || ({} as Paginated<BasePatient>);
}

//IndicatorsResumes

export async function handleIndicator1Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 1,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator2Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 2,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator3Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 3,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator4Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 4,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator5Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 5,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator6Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 6,
		}
	);
	return response.data || ({} as IndicatorResume);
}

export async function handleIndicator7Resume(
	indicatorResumeParams: Omit<IndicatorResumeParams, "indicator">
) {
	const response = await get<IndicatorResume>(
		`/uf/${indicatorResumeParams.uf}/city/${indicatorResumeParams.ibgeCode}/indicator/getResume`,
		{
			...indicatorResumeParams,
			indicator: 7,
		}
	);
	return response.data || ({} as IndicatorResume);
}

//Reports

export async function fetchIndicators(
	uf: string,
	ibgeCode: string,
	quarter: string,
	cnes?: string,
	ine?: string
) {
	const { data } = await get<IndicatorsData>(`/uf/${uf}/city/${ibgeCode}/indicator/dashboard`, {
		quarter,
		cnes,
		ine,
	});
	return data || ({} as IndicatorsData);
}

export async function handleIndicatorsGroupedByHealthTeam({
	uf,
	ibgeCode,
	...params
}: IndicatorsGroupedByHealthTeamParams) {
	const { data } = await get<IndicatorsHealthTeam>(
		`/uf/${uf}/city/${ibgeCode}/indicator/dashboardGroupedByHealthTeam`,
		params
	);
	return data || ({} as IndicatorsHealthTeam);
}
