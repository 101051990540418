import { Props as SelectProps } from "react-select";

import { HealthTeam } from "@/@types/esus/HealthTeam";
import { Option } from "@/@types/generics/Option";
import { SelectComponent } from "../SelectComponent";
import { useHealthTeams } from "@/hooks/useHealthTeam";

type Props = SelectProps & {
	healthTeams?: HealthTeam[];
	isLoading?: boolean;
	useDefault?: boolean;
};

export const defaultHealthTeam = { value: "", label: "Todas as equipes" };

export function HealthTeamsSelect({ healthTeams, isLoading, useDefault, ...rest }: Props) {
	const { healthTeams: contextHealthTeams } = useHealthTeams();
	const healthTeamsSelected = useDefault ? contextHealthTeams : healthTeams || [];
	const options: Option<string>[] = [
		defaultHealthTeam,
		...healthTeamsSelected.map((healthTeam) => ({
			value: healthTeam.ine,
			label: `${healthTeam.name}-${healthTeam.ine}`,
		})),
	];

	return (
		<div className="d-flex gap-2 flex-column">
			<label htmlFor={rest.id}>Equipes:</label>
			<SelectComponent
				className="fs-8"
				options={options}
				id={rest.id}
				isLoading={isLoading}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma equipe encontrada"}
				{...rest}
			/>
		</div>
	);
}
