import { useEffect, useState } from "react";
import { Props as SelectProps } from "react-select";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { getMicroAreas } from "@/services/esus/citizenService";

import { SelectComponent } from "../SelectComponent";
import { Option } from "@/@types/generics/Option";

type Props = SelectProps & {
	noDefaultOption?: boolean;
};

export function MicroAreasSelect({ noDefaultOption, ...rest }: Props) {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [microAreas, setMicroAreas] = useState<string[]>([]);
	const options: Option<string | null>[] = [
		{ label: "Todas", value: null },
		...microAreas?.map((area) => ({ value: area, label: area })),
	];

	async function handleMicroAreas() {
		return await getMicroAreas({ uf: uf, ibgeCode: ibgeCode, cnes: cnes });
	}

	async function fetch() {
		setMicroAreas(await handleMicroAreas());
	}
	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column">
			<label title="Micro áreas" htmlFor={rest.id} className="form-label">
				Micro áreas:
			</label>
			<SelectComponent
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum perfil encontrado"}
				{...rest}
			/>
		</div>
	);
}
