import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import { FaDatabase } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { TbReportSearch } from "react-icons/tb";
import { RiFileExcel2Fill } from "react-icons/ri";

import {
	GetTerritorialCitizenRegistration,
	GetTerritorialCitizenRegistrationReport,
	TerritorialCitizenRegister,
} from "@/services/esus/territorialCitizenService";
import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Option } from "@/@types/generics/Option";
import { getCurrentQuarter } from "@/utils/getQuaterCurrent";
import { useDebounce } from "@/hooks/useDebounce";
import { Paginated } from "@/@types/generics/paginated";
import { exportExcel } from "@/utils/exports";
import { VulnerableCitizenEnum } from "@/utils/enums/VulnerableCitizenEnum";
import { GenericObject } from "@/@types/generics/genericals";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { QuartersSelect } from "@/components/QuartersSelect";
import { TerritorialRegistrationStatusSelect } from "@/components/TerritorialRegistrationStatus";
import { SearchInput } from "@/components/SearchInput";
import {
	AccompanimentRegistrationTable,
	getTerritorialCitizenRegisterStatus,
} from "./AccompanimentRegistrationTable";
import { VulnerableCitizenSelect } from "@/components/VulnerableCitizenSelect";
import { LoadingScreen } from "@/components/LoadingScreen";
import { MicroAreasSelect } from "@/components/MicroAreasSelect";

export function AccompanimentRegistrationReport() {
	const { setTitle } = useLayout();
	const { uf, ibgeCode , cnes} = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingExportExcel, setIsLoadingExportExcel] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [territorialCitizensRegistration, setTerritorialCItizensRegistration] =
		useState<Paginated<TerritorialCitizenRegister>>();
	const [searchFullName, setSearcFullName] = useState("");
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>();
	const [
		selectedTerritorialCitizenRegistrationStatus,
		setSelectedTerritorialCitizenRegistrationStatus,
	] = useState<Option<number | null>>({ value: null, label: "Todas" } as Option<number | null>);
	const [selectedVulnerableCitizenStatus, setSelectedVulnerableCitizenStatus] = useState<
		Option<number | null>
	>({ value: null, label: "Todas" } as Option<number | null>);
	const [selectedMicroArea, setSelectedMicroArea] = useState<Option<string | null>>({
		value: null,
		label: "Todas",
	} as Option<string | null>);
	const quarter = getCurrentQuarter();
	const [selectedQuarter, setSelectedQuarter] = useState<Option<string>>({
		value: quarter,
		label: quarter,
	});

	async function handleTerritorialCitizenRegistration(pageNumber?: number) {
		return await GetTerritorialCitizenRegistration({
			uf: uf,
			ibgeCode: ibgeCode,
			quarter: selectedQuarter.value,
			fullName: searchFullName,
			ine: selectedHealthTeam?.value,
			cnes: cnes,
			citizenRegisterStatus: selectedTerritorialCitizenRegistrationStatus?.value,
			vulnerableCitizenStatus: selectedVulnerableCitizenStatus?.value,
			microArea: selectedMicroArea?.value,
			pageNumber: pageNumber || 1,
			pageSize: 50,
		});
	}

	async function handleTerritorialCitizenRegistrationReport() {
		return await GetTerritorialCitizenRegistrationReport({
			uf: uf,
			ibgeCode: ibgeCode,
			quarter: selectedQuarter.value,
			fullName: searchFullName,
			ine: selectedHealthTeam?.value,
			cnes: cnes,
			citizenRegisterStatus: selectedTerritorialCitizenRegistrationStatus?.value,
			vulnerableCitizenStatus: selectedVulnerableCitizenStatus?.value,
		});
	}

	async function fetch(pageNumber?: number) {
		setIsLoading(true);
		setTerritorialCItizensRegistration(await handleTerritorialCitizenRegistration(pageNumber));
		setIsLoading(false);
	}

	async function handleExportTable() {
		setIsLoadingExportExcel(true);
		const data = await handleTerritorialCitizenRegistrationReport();
		setIsLoadingExportExcel(false);

		if (Object.keys(data).length) {
			const excelData: GenericObject[] = transformToGenericObject(data);
			exportExcel(excelData, `Relatorio de situação cadastral e acompanhamento`);
		}
	}

	function transformToGenericObject(data: TerritorialCitizenRegister[]) {
		return data.map(
			(territorialCitizenRegister) =>
				({
					Nome: territorialCitizenRegister.citizen?.fullName || "",
					Cpf: territorialCitizenRegister.citizen?.cpf || "",
					Cns: territorialCitizenRegister.citizen?.cns || "",
					"Data de Nascimento": territorialCitizenRegister.citizen?.birthdate
						? dayjs(territorialCitizenRegister.citizen?.birthdate).format("DD/MM/YYYY")
						: "",
					Beneficiário: territorialCitizenRegister.hasBenefits ? "SIM" : "NÃO",
					Area: territorialCitizenRegister.citizen.area,
					Equipe: territorialCitizenRegister.citizen.healthTeam?.name || "",
					FCI: territorialCitizenRegister?.individualRecordStatus || "",
					"Ultima FCI": territorialCitizenRegister.lastIndividualRecord?.registerDate
						? dayjs(
								territorialCitizenRegister.lastIndividualRecord.registerDate
						  ).format("DD/MM/YYYY")
						: "",
					FCDT: territorialCitizenRegister?.residenceFamilyRecordStatus || "",
					"Ultima FCDT": territorialCitizenRegister.lastResidenceFamilyRecord
						?.registerDate
						? dayjs(
								territorialCitizenRegister.lastResidenceFamilyRecord.registerDate
						  ).format("DD/MM/YYYY")
						: "",
					"Status de registro": getTerritorialCitizenRegisterStatus(
						territorialCitizenRegister.registerStatus
					),
					Acompanhado: territorialCitizenRegister.isAccompanied ? "SIM" : "NÃO",
				} as GenericObject)
		);
	}

	useEffect(() => {
		if (pageNumber > 1) {
			setPageNumber(1);
		}
		fetch();
		setTitle("Relatório de situação cadastral e acompanhamento");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName]);

	return (
		<div className="px-2 pb-2">
			<LoadingScreen loading={isLoadingExportExcel} />
			<Form
				className="py-2 bg-white"
				onSubmit={(event) => {
					event.preventDefault();
					setPageNumber(1);
					fetch();
				}}
			>
				<Row className="d-flex justify-content-between mx-2 ">
					<Col sm={12} md={3} lg={2} xl={2}>
						<QuartersSelect
							value={selectedQuarter}
							onChange={(newValue) => setSelectedQuarter(newValue as Option<string>)}
						/>
					</Col>
					<Col sm={12} md={3} lg={3} xl={3}>
						<HealthTeamsSelect
							useDefault
							value={selectedHealthTeam}
							onChange={(newValue) =>
								setSelectedHealthTeam(newValue as Option<string>)
							}
						/>
					</Col>
					<Col sm={12} md={3} lg={2} xl={2}>
						<MicroAreasSelect
							value={selectedMicroArea}
							onChange={(newValue) =>
								setSelectedMicroArea(newValue as Option<string | null>)
							}
						/>
					</Col>
					<Col sm={12} md={3} lg={3} xl={3}>
						<TerritorialRegistrationStatusSelect
							value={selectedTerritorialCitizenRegistrationStatus}
							onChange={(newValue) =>
								setSelectedTerritorialCitizenRegistrationStatus(
									newValue as Option<number | null>
								)
							}
						/>
					</Col>
					<Col sm={12} md={3} lg={2} xl={2}>
						<VulnerableCitizenSelect
							value={selectedVulnerableCitizenStatus}
							onChange={(newValue) =>
								setSelectedVulnerableCitizenStatus(
									newValue as Option<number | null>
								)
							}
						/>
					</Col>
					<Col
						sm={4}
						md={3}
						lg={3}
						xl={3}
						className="d-flex align-items-end justify-content-center gap-2 mt-3"
					>
						<Button
							className="fs-8 p-3 text-nowrap w-100"
							variant="secondary"
							onClick={() =>
								territorialCitizensRegistration?.data &&
								territorialCitizensRegistration?.data.length > 0 &&
								handleExportTable()
							}
						>
							<RiFileExcel2Fill />
							Exportar
						</Button>
						<Button type="submit" className="fs-8 p-3 text-nowrap w-100">
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</Col>
				</Row>
			</Form>
			{selectedVulnerableCitizenStatus?.value ===
				VulnerableCitizenEnum.CITIZENS_RECEIVING_SOCIAL_BENEFITS && (
				<Alert className="mt-3" variant="warning">
					Atenção: Para visualizar os beneficiários do bolsa família é necessário importar
					no PEC o arquivo da vigência disponibilizado no SISAB.
				</Alert>
			)}
			<Card className="my-3 bg-white">
				<Card.Title className="d-flex align-items-center gap-2 p-2 text-primary mb-0">
					<TbReportSearch size={30} className="bg-primary text-white rounded p-1 me-2" />
					Tabela relacionada
				</Card.Title>
			</Card>
			<Card className="bg-white">
				<Card.Body className="p-2">
					<Col xs={12} sm={6} md={4} lg={4} xl={4} className="d-flex flex-column my-2">
						<label className="mb-2">
							<strong>Pesquisar Cidadão:</strong>
						</label>
						<SearchInput
							placeholder="Pesquisar"
							value={searchFullName}
							setValue={(value) => {
								setSearcFullName(value);
							}}
						/>
					</Col>
					<AccompanimentRegistrationTable
						territorialCitizensRegistration={territorialCitizensRegistration}
						isLoading={isLoading}
					/>
				</Card.Body>
				<Card.Footer>
					{territorialCitizensRegistration?.totalRecords ? (
						<PaginationLinks
							itemsPerPage={territorialCitizensRegistration.pageSize}
							totalPages={territorialCitizensRegistration.totalRecords}
							changeSelectedPage={(pageNumber) => {
								setPageNumber(pageNumber);
								fetch(pageNumber);
							}}
							pageNumber={pageNumber || 0}
						/>
					) : (
						!isLoading && (
							<NoRecordsFeedback
								message={"Nenhum resultado encontrado."}
								icon={<FaDatabase />}
							/>
						)
					)}
				</Card.Footer>
			</Card>
		</div>
	);
}
