import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

import { useUnits } from "@/hooks/useUnits";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useProfessionals } from "@/hooks/useProfessionals";
import { Option } from "@/@types/generics/Option";

import { ProfessionalsSelect } from "@/components/ProfessionalsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { UnitsSelect } from "@/components/UnitsSelect";

type Props = {
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	setCnes: (cnes: string) => void;
	setIne: (ine: string) => void;
	setCns: (cns: string) => void;
	handleFilters: () => void;
};

export function ProfessionalAttendanceFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	setCnes,
	setIne,
	setCns,
	handleFilters,
}: Props) {
	const { units } = useUnits();
	const { healthTeams } = useHealthTeams();
	const { professionals } = useProfessionals();

	return (
		<Form
			className="pt-2"
			onSubmit={(event) => {
				event.preventDefault();
				handleFilters();
			}}
		>
			<Row className="mx-2">
				<Col lg={4} className="mb-3">
					<Form.Label>Período</Form.Label>
					<div className="d-flex gap-3">
						<InputGroup>
							<Form.Control
								type="date"
								value={startDate}
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup>
							<Form.Control
								type="date"
								value={endDate}
								placeholder="data final"
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</InputGroup>
					</div>
				</Col>
				<Col lg={4} className="mb-3">
					<UnitsSelect
						units={units}
						onChange={(event) => setCnes((event as Option<string>).value)}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhuma unidade encontrada"}
					/>
				</Col>
				<Col lg={4} className="mb-3">
					<HealthTeamsSelect
						healthTeams={healthTeams}
						onChange={(event) => setIne((event as Option<string>).value)}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhuma equipe encontrada"}
					/>
				</Col>
				<Col lg={4} className="mb-3">
					<ProfessionalsSelect
						professionals={professionals}
						onChange={(event) => setCns((event as Option<string>).value)}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhum professional encontrado"}
					/>
				</Col>
				<Col lg={2} className="align-items-end mb-3">
					<div className="d-flex h-100 align-items-end">
						<Button type="submit">
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}
