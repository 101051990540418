import { useState } from "react";
import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FaEllipsisV, FaEye, FaInfoCircle } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";

import { TerritorialCitizenRegisterStatusEnum } from "@/utils/enums/TerritorialCitizenRegisterStatus";
import { handleColumnToggle } from "@/utils/handleColumnTogle";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { TerritorialCitizenRegister } from "@/services/esus/territorialCitizenService";
import { Paginated } from "@/@types/generics/paginated";

import { TableSkeletons } from "@/components/TableSkeletons";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { ModalTerritorialCitizenRegisterDetail } from "./ModalTerritorialCitizenRegisterDetail";

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	name: Column;
	identifier: Column;
	cns: Column;
	birthdate: Column;
	beneficiary: Column;
	area: Column;
	healthTeam: Column;
	fciStatus: Column;
	lastFci: Column;
	fcdtStatus: Column;
	lastFcdt: Column;
	registerStatus: Column;
	accompanied: Column;
};

type Props = {
	isLoading: boolean;
	territorialCitizensRegistration?: Paginated<TerritorialCitizenRegister>;
};

export function getTerritorialCitizenRegisterStatus(status?: TerritorialCitizenRegisterStatusEnum) {
	switch (status) {
		case TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED:
			return "FCI atualizada e FCDT desatualizada";
		case TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_NOT_REGISTERED:
			return "FCDT não cadastrada";
		case TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_OUTDATED:
			return "FCI e FCDT desatualizados";
		case TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED:
			return "FCI e FCDT atualizadas";
		case TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_UPDATED:
			return "FCI desatualizada e FCDT atualizada";
		case TerritorialCitizenRegisterStatusEnum.FCDT_UPDATED_WITHOUT_FCI:
			return "FCDT atualizada sem FCI";
		case TerritorialCitizenRegisterStatusEnum.FCDT_OUTDATED_WITHOUT_FCI:
			return "FCDT desatualizada sem FCI";
		case TerritorialCitizenRegisterStatusEnum.WITHOUT_FCI_AND_FCDT:
			return "Sem FCI e Sem FCDT (Cadastro Rápido)";

		default:
			return "N/A";
	}
}

export function AccompanimentRegistrationTable({
	territorialCitizensRegistration,
	isLoading,
}: Props) {
	const [selectedTerritorialCitizenRegister, setSelectedTerritorialCitizenRegister] =
		useState<TerritorialCitizenRegister>();
	const [showAccompanimentDetailsModal, setShowAccompanimentDetailsModal] = useState(false);

	const [columns, setColumns] = useState<ColumnsTable>({
		name: { title: "Nome", visible: true },
		identifier: { title: "CPF", visible: false },
		cns: { title: "CNS", visible: false },
		birthdate: { title: "DN", visible: false },
		beneficiary: { title: "Beneficiário", visible: true },
		area: { title: "MC", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		fciStatus: { title: "FCI Status", visible: true },
		lastFci: { title: "Última FCI", visible: false },
		fcdtStatus: { title: "FCDT Status", visible: true },
		lastFcdt: { title: "Última FCDT", visible: false },
		registerStatus: { title: "Status de registro", visible: false },
		accompanied: { title: "Acompanhamento status", visible: true },
	});

	return (
		<div>
			<ModalTerritorialCitizenRegisterDetail
				selectedTerritorialCitizenRegister={selectedTerritorialCitizenRegister}
				show={showAccompanimentDetailsModal}
				onHide={() => setShowAccompanimentDetailsModal(false)}
			/>
			<Table className="bg-white" striped bordered hover responsive>
				<thead className="text-center fs-7">
					<tr className="fw-bolder">
						<th className={`px-2 ${!columns.name.visible && "d-none"}`}>Nome</th>
						<th className={`${!columns.identifier.visible && "d-none"}`}>CPF</th>
						<th className={`text-nowrap ${!columns.cns.visible && "d-none"}`}>CNS</th>
						<th className={`${!columns.birthdate.visible && "d-none"}`}>DN</th>
						<th className={`text-nowrap ${!columns.beneficiary.visible && "d-none"}`}>
							<label className="me-2">Beneficiário</label>
							<CardOverlayTrigger
								header="Beneficiários:"
								message="Identificados da relação do sisab que foram exportados para o PEC"
								icon={<FaInfoCircle className="text-muted" />}
							/>
						</th>
						<th className={`${!columns.area.visible && "d-none"}`}>MC</th>
						<th className={`${!columns.healthTeam.visible && "d-none"}`}>Equipe</th>
						<th className={`${!columns.fciStatus.visible && "d-none"}`}>FCI status</th>
						<th className={`${!columns.lastFci.visible && "d-none"}`}>Última FCI</th>
						<th className={`${!columns.fcdtStatus.visible && "d-none"}`}>
							FCDT status
						</th>
						<th className={`${!columns.lastFcdt.visible && "d-none"}`}>Última FCDT</th>
						<th className={`${!columns.registerStatus.visible && "d-none"}`}>
							Status de registro
						</th>
						<th className={`${!columns.accompanied.visible && "d-none"}`}>
							Acompanhamento status{" "}
							<CardOverlayTrigger
								header="Cidadão acompanhado"
								children={
									<div className="text-start">
										<p>
											Aquele que apresenta mais de um contato assistencial com
											profissional de saúde no período de um ano (12 meses), a
											partir do último mês do quadrimestre avaliado, sendo
											necessário que pelo menos um desses contatos seja um
											atendimento, podendo ser individual, coletivo e/ou
											domiciliar. Entende-se por “mais de um contato
											assistencial com profissional de saúde”: atendimento
											combinado com procedimento ou atendimento combinado com
											atendimento
										</p>
										<p>
											<strong>Procedimentos :</strong> Registros de vacinação
											(MIV) e de outros procedimentos realizados, por exemplo,
											escuta inicial ou curativo (MIP).
										</p>

										<p>
											<strong> Atendimentos :</strong> Registros de
											atendimento individual (MIAI), de atendimento
											odontológico (MIAOI), de atividade coletiva (MIAC), de
											marcadores de consumo alimentar (MIMCA) e de visita
											domiciliar e territorial (MIVDT).
										</p>
									</div>
								}
								icon={<FaInfoCircle />}
							/>
						</th>
						<th className="text-nowrap pb-0">
							Ações
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable,
																setColumns
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody className="fs-8">
					{!isLoading && territorialCitizensRegistration?.data ? (
						<>
							{territorialCitizensRegistration?.data.map((citizen, index) => (
								<tr key={`item-${index}`}>
									<td className={`px-2 ${!columns.name.visible && "d-none"}`}>
										{citizen.citizen.fullName}
									</td>
									<td
										className={`text-nowrap ${
											!columns.identifier.visible && "d-none"
										}`}
									>
										{formatIdentifier(citizen.citizen.cpf)}
									</td>
									<td
										className={`text-nowrap ${
											!columns.cns.visible && "d-none"
										}`}
									>
										{citizen.citizen.cns}
									</td>
									<td
										className={`${!columns.birthdate.visible && "d-none"}`}
										title={citizen.citizen.age}
									>
										{dayjs(citizen.citizen.birthdate).format("DD/MM/YYYY")}
									</td>
									<td
										className={`text-center ${
											!columns.beneficiary.visible && "d-none"
										}`}
									>
										{citizen.hasBenefits ? "SIM" : "NÃO"}
									</td>
									<td className={`${!columns.area.visible && "d-none"}`}>
										{citizen.citizen.area}
									</td>
									<td className={`${!columns.healthTeam.visible && "d-none"}`}>
										{citizen.citizen.healthTeam?.name}
									</td>
									<td
										className={`text-center ${
											!columns.fciStatus.visible && "d-none"
										}`}
									>
										{citizen.individualRecordStatus ? (
											<Badge
												bg={`${
													citizen.individualRecordStatus === "Atualizado"
														? "primary"
														: "danger"
												}`}
											>
												{citizen.individualRecordStatus}
											</Badge>
										) : (
											<Badge bg="muted">Não cadastrado</Badge>
										)}
									</td>
									<td
										className={`text-center ${
											!columns.lastFci.visible && "d-none"
										}`}
									>
										{citizen.lastIndividualRecord?.registerDate
											? dayjs(
													citizen.lastIndividualRecord?.registerDate
											  ).format("DD/MM/YYYY")
											: "N/A"}
									</td>
									<td
										className={`text-center ${
											!columns.fcdtStatus.visible && "d-none"
										}`}
									>
										{citizen.residenceFamilyRecordStatus ? (
											<Badge
												bg={`${
													citizen.residenceFamilyRecordStatus ===
													"Atualizado"
														? "primary"
														: "danger"
												}`}
											>
												{citizen.residenceFamilyRecordStatus}
											</Badge>
										) : (
											<Badge bg="muted"> Sem cadastro</Badge>
										)}
									</td>
									<td
										className={`text-center ${
											!columns.lastFcdt.visible && "d-none"
										}`}
									>
										{citizen.lastResidenceFamilyRecord?.registerDate
											? dayjs(
													citizen.lastResidenceFamilyRecord?.registerDate
											  ).format("DD/MM/YYYY")
											: "N/A"}
									</td>
									<td
										className={`text-center ${
											!columns.registerStatus.visible && "d-none"
										}`}
									>
										{citizen.registerStatus
											? getTerritorialCitizenRegisterStatus(
													citizen.registerStatus
											  )
											: "N/A"}
									</td>
									<td
										className={`text-center ${
											!columns.accompanied.visible && "d-none"
										}`}
									>
										{citizen.isAccompanied ? "SIM" : "NÃO"}
									</td>
									<td className="text-center">
										<Button
											className="bg-primary py-1 px-3"
											onClick={() => {
												setSelectedTerritorialCitizenRegister(citizen);
												setShowAccompanimentDetailsModal(true);
											}}
										>
											<FaEye className="fs-4" />
										</Button>
									</td>
								</tr>
							))}
						</>
					) : (
						isLoading && (
							<TableSkeletons
								numberOfCells={
									Object.values(columns).reduce(
										(count, column) =>
											count + (column?.visible === true ? 1 : 0),
										0
									) + 1
								}
							/>
						)
					)}
				</tbody>
			</Table>
		</div>
	);
}
