import { useEffect, useState } from "react";
import { Props as SelectProps } from "react-select";

import { getScheduleStatus } from "@/services/esus/TScheduleService";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Option } from "@/@types/generics/Option";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	noDefaultOption?: boolean;
};

export function ScheduleStatusSelect({ noDefaultOption, ...rest }: Props) {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [scheduleStatus, setScheduleStatus] = useState<TScheduleStatus[]>([]);

	const defaultOption = { label: "TODOS" };
	const options = [
		...scheduleStatus?.map<Option<TScheduleStatus>>((status) => ({
			value: status,
			label: status.name,
		})),
	];

	async function fetch() {
		setScheduleStatus(
			await getScheduleStatus({
				uf,
				ibgeCode,
				cnes
			})
		);
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column">
			<label title="Categorias profissional" htmlFor={rest.id} className="mb-2">
				Status de agendamento:
			</label>
			<SelectComponent
				options={[defaultOption, ...options]}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum perfil encontrado"}
				{...rest}
			/>
		</div>
	);
}
