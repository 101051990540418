import { useEffect, useState } from "react";

import { Props as SelectProps } from "react-select";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { getTImmunobiologicals } from "@/services/esus/TImmunobiologicalService";
import { TImmunobiological } from "@/@types/esus/TImmunobiological";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps;

export function TImmunobiologicalSelect({ ...rest }: Props) {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [immunobiologicals, setImmunobiologicals] = useState<TImmunobiological[]>([]);

	async function handleTImmunobiologicals() {
		setImmunobiologicals(await getTImmunobiologicals({ uf, ibgeCode, cnes }));
	}

	const defaultImmunobiological = { value: {} as TImmunobiological, label: "Todas as vacinas" };

	const options = [
		defaultImmunobiological,
		...immunobiologicals?.map((immunobiological) => ({
			value: immunobiological,
			label: immunobiological.name,
		})),
	];

	useEffect(() => {
		handleTImmunobiologicals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="d-flex flex-column gap-2">
			<label htmlFor={rest.id}>Vacinas:</label>
			<SelectComponent
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma vacina encontrada"}
				{...rest}
			/>
		</div>
	);
}
