import { Ciap } from "@/@types/esus/Ciap";
import { get } from "../api";

type CiapsParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	search: string;
};
export async function getCiaps({ uf, ibgeCode, ...ciapsParams }: CiapsParams) {
	const response = await get<Ciap[]>(`/uf/${uf}/city/${ibgeCode}/Ciap/Get`, ciapsParams);
	return response.data || [];
}
