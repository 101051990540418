import { Account } from "@/@types/app/Account";
import { get, post } from "../api";
import { Paginated } from "@/@types/generics/paginated";
import { PaginationFilter } from "@/@types/generics/genericals";
import { CityPopulationClassificationEnum } from "@/utils/enums/CityPopulationClassificationEnum";
import { CityPopulationClassificationHistory } from "@/@types/app/cityPopulationClassificationHistories";

export type DatabaseConnection = {
	database: string;
	password: string;
	port: string;
	server: string;
	userid: string;
};

type GetAdminCitiesPaginatedParams = PaginationFilter & {
	cityName?: string;
};

export type UpdateCityPopulationClassificationParams = {
	ibgeCode: string;
	population: number;
};

type CityPopulationClassificationHistoryParams = {
	cityId?: number;
	quarter?: string;
};

export async function updateCity(city: Account) {
	const response = await post(`/City/UpdateCity`, city);
	return response.status === 200;
}

export async function getNotLinkedCities() {
	const { data } = await get<Account[]>("/City/GetNotLinkedCities");
	return data || [];
}

export async function getActivatedCities() {
	const { data } = await get<Account[]>("/City/GetActivatedCities");
	return data || [];
}

export async function getAdminCitiesPaginated(params: GetAdminCitiesPaginatedParams) {
	const { data } = await get<Paginated<Account>>(`/City/AdminCitiesPaginated`, params);
	return data || ({} as Paginated<Account>);
}

export async function getAdminCity(ibgeCode: string) {
	const { data } = await get<Account>("/City/AdminCity", { ibgeCode });
	return data || ({} as Account);
}

export async function updateDatabaseConnectionString(
	ibgeCode: string,
	databaseConfiguration: DatabaseConnection
) {
	const response = await post(`/City/UpdateDatabaseConnectionString`, {
		ibgeCode: ibgeCode,
		...databaseConfiguration,
	});
	return response.status === 200;
}

export async function updateCityPopulationClassification(
	updateCityPopulationClassification: UpdateCityPopulationClassificationParams
) {
	return await post<Account>(
		`/City/UpdateCityPopulationClassification`,
		updateCityPopulationClassification
	);
}

export async function GetCityPopulationClassification(
	params: CityPopulationClassificationHistoryParams
) {
	const { data } = await get<CityPopulationClassificationHistory>(
		`/City/GetCityPopulationClassification`,
		params
	);
	return (
		data ||
		({
			cityPopulationClassificationId: CityPopulationClassificationEnum["UP_TO_20K"],
		} as CityPopulationClassificationHistory)
	);
}
