import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";

import "./styles.scss";

const AsideDefault: FC = () => {
	const { config, classes } = useLayout();
	const { aside } = config;

	const [fixed, setFixed] = useState(true);
	const [minimized, setMinimized] = useState(false);

	function handleToggleAside() {
		setFixed(!fixed);
	}

	useEffect(() => {
		const asideElement = document.getElementById("kt_aside");

		asideElement?.addEventListener("mouseenter", () => {
			setMinimized(false);
		});

		asideElement?.addEventListener("mouseleave", () => {
			setMinimized(true);
		});
	}, []);

	return (
		<div
			id="kt_aside"
			className={clsx("aside", classes.aside.join(" "))}
			data-kt-drawer="true"
			data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#kt_aside_mobile_toggle"
		>
			<div className="aside-logo flex-column-auto" id="kt_aside_logo">
				{aside.theme === "dark" && (
					<Link to="/home">
						<img
							alt="Logo"
							className="logo"
							style={{ height: "80px" }}
							src={toAbsoluteUrl("/media/logos/logo-main.png")}
						/>
					</Link>
				)}
				{aside.theme === "light" && (
					<Link to="/home">
						<img
							alt="Logo"
							className="h-25px logo"
							src={toAbsoluteUrl("/media/logos/logo-main.png")}
						/>
					</Link>
				)}

				{aside.minimize && (
					<div
						id="kt_aside_toggle"
						className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
						data-kt-toggle="true"
						data-kt-toggle-state="active"
						data-kt-toggle-target="body"
						data-kt-toggle-name="aside-minimize"
						onClick={handleToggleAside}
					>
						<KTSVG
							path={"/media/icons/duotune/arrows/arr080.svg"}
							className={"svg-icon-1 rotate-180"}
						/>
					</div>
				)}
			</div>

			<div className="aside-menu flex-column-fluid">
				<AsideMenu asideMenuCSSClasses={classes.asideMenu} />
			</div>

			<div
				className="aside-footer d-flex flex-column align-items-center pt-5 pb-7 px-5 fs-8 text-light"
				id="kt_aside_footer"
			>
				{window.screen.width > 768 ? (
					fixed || !minimized ? (
						<div className="d-flex flex-column text-center">
							<img
								alt="Logo Multintegrada"
								src={toAbsoluteUrl(
									"/media/logos/MultIntegrada-Horizontal-White.png"
								)}
								height={30}
								className="logo-company"
							/>
							<label className="text-white fs-8">versão 2.5.0</label>
						</div>
					) : (
						<div className="d-flex flex-column text-center">
							<img
								alt="Logo"
								src={toAbsoluteUrl("/media/logos/logo-icon.svg")}
								className="logo-company-minimize"
								height={20}
							/>
							<label className="mt-2 text-white fs-8">2.5.0</label>
						</div>
					)
				) : (
					<div className="d-flex flex-column text-center">
						<img
							alt="Logo Multintegrada"
							src={toAbsoluteUrl("/media/logos/MultIntegrada-Horizontal-White.png")}
							height={20}
							className="logo-company"
						/>
						<label className="mt-2 text-white fs-8">versão 2.5.0</label>
					</div>
				)}
			</div>
		</div>
	);
};

export { AsideDefault };
