import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsClipboardData } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useHealthTeams } from "@/hooks/useHealthTeam";
import { Option } from "@/@types/generics/Option";
import {
	GetTerritorialCitizenFinalEvaluationResultResumeDashboard,
	TerritorialCitizenFinalEvaluationResultResume,
} from "@/services/esus/territorialCitizenService";
import { ResumeGrouped } from "@/services/esus/attendanceService";
import { VulnerableCitizenEnum } from "@/utils/enums/VulnerableCitizenEnum";
import { calculatePaymentByHealthTeam } from "@/utils/healthTeamQualification";
import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import {
	CityPopulationClassification,
	citiesPopulationsClassifications,
} from "@/utils/cityPopulationClasssification";
import { GetCityPopulationClassification } from "@/services/app/cityService";
import { CityPopulationClassificationEnum } from "@/utils/enums/CityPopulationClassificationEnum";

import { ResourceEvaluationDimensionCards } from "./ResourceEvaluationDimensionCards";
import { DonutChart } from "@/components/Charts/DonutChart";
import { PieChart } from "@/components/Charts/PieChart";
import { QuartersSelect } from "@/components/QuartersSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { TableResourceEvaluatedByHealthTeam } from "./TableResourceEvaluatedByHealthTeam";
import { LoadingScreen } from "@/components/LoadingScreen";

export type ResumeDashboardEvaluatedDimension = {
	accompanimentResumes: ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>[];
	registerResumes: ResumeGrouped<number, object>[];
	healthTeam: HealthTeam;
	HealthTeamType: HealthTeamTypeEnum;
}[];

export function ResourceEvaluationDimension() {
	const { healthTeams } = useHealthTeams();
	const { uf, cnes, ibgeCode, currentAccount } = useCurrentAccount();
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>();
	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<Option<string>>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingCityClassificationHistory, setIsLoadingCityClassificationHistory] =
		useState(false);
	const [territorialCitizenFinalEvaluatedResume, setTerritorialCitizenFinalEvaluatedResume] =
		useState<TerritorialCitizenFinalEvaluationResultResume>();

	const [cityPopulationClassification, setCityPopulationClassification] = useState<
		CityPopulationClassification | undefined
	>();

	const resumeResourceEvaluationDimensionByHealthTeamESFDashboard = generateResumeDashboard(
		territorialCitizenFinalEvaluatedResume?.territorialCitizenRegistrationDashboardResume
			?.territorialCitizenRegisterByPeriodAndGroupedByhealthTeamESFResume ?? [],
		territorialCitizenFinalEvaluatedResume?.territorialCitizenAccompanimentDashboardResume
			?.territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamESFResume ?? [],
		HealthTeamTypeEnum.ESF
	);

	const resumeResourceEvaluationDimensionByHealthTeamEAPDashboard = generateResumeDashboard(
		territorialCitizenFinalEvaluatedResume?.territorialCitizenRegistrationDashboardResume
			?.territorialCitizenRegisterByPeriodAndGroupedByhealthTeamEAPResume ?? [],
		territorialCitizenFinalEvaluatedResume?.territorialCitizenAccompanimentDashboardResume
			?.territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamEAPResume ?? [],
		HealthTeamTypeEnum.EAP
	);

	const resumeDashboard = resumeResourceEvaluationDimensionByHealthTeamEAPDashboard
		.concat(resumeResourceEvaluationDimensionByHealthTeamESFDashboard)
		.sort((a, b) => a.healthTeam.name.localeCompare(b.healthTeam.name));

	function generateResumeDashboard(
		territorialCitizenRegisterByPeriodAndGroupedByhealthTeamResume: ResumeGrouped<
			HealthTeam,
			ResumeGrouped<number, object>
		>[],
		territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume: ResumeGrouped<
			HealthTeam,
			ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>
		>[],
		HealthTeamType: HealthTeamTypeEnum
	) {
		const registerResumes =
			territorialCitizenRegisterByPeriodAndGroupedByhealthTeamResume?.map(
				(groupedHealthTeamByRegisterStatus) => {
					return {
						groupRegisterStatus: groupedHealthTeamByRegisterStatus.values ?? [],
						healhTeam: groupedHealthTeamByRegisterStatus.key,
						healhTeamType: HealthTeamType,
					};
				}
			) ?? [];
		const accompanimentResumes =
			territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume?.map(
				(groupedHealthTeamByAccompanimentStatus) => {
					return {
						groupAccompanimentStatus:
							groupedHealthTeamByAccompanimentStatus.values ?? [],
						healthTeam: groupedHealthTeamByAccompanimentStatus.key,
						healthTeamType: HealthTeamType,
					};
				}
			) ?? [];

		const group: ResumeDashboardEvaluatedDimension = registerResumes.map((register) => {
			return {
				accompanimentResumes:
					accompanimentResumes.find(
						(accompanimentResume) =>
							accompanimentResume.healthTeam.ine === register.healhTeam.ine
					)?.groupAccompanimentStatus ?? [],
				registerResumes: register.groupRegisterStatus,
				healthTeam: register.healhTeam,
				HealthTeamType: HealthTeamType,
			};
		});

		return group;
	}

	const resumeFinalByHealthTeam = resumeDashboard.map((resumeByHealhTeam) => {
		return {
			resumeByHealthTeam: resumeByHealhTeam,
			resultsPaymentByHealthTeam: calculatePaymentByHealthTeam(
				resumeByHealhTeam.HealthTeamType,
				cityPopulationClassification ?? ({} as CityPopulationClassification),
				resumeByHealhTeam.registerResumes,
				resumeByHealhTeam.accompanimentResumes,
				cityPopulationClassification?.parameter.esf.min ??
					CityPopulationClassificationEnum.UP_TO_20K
			),
		};
	});

	const totalPaymentByEsf = resumeFinalByHealthTeam
		.filter(
			(healthTeam) => healthTeam.resumeByHealthTeam.HealthTeamType === HealthTeamTypeEnum.ESF
		)
		.map((resource) => resource.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.value)
		.reduce((acc, curr) => acc + curr, 0);
	const totalPaymentByEap = resumeFinalByHealthTeam
		.filter(
			(healthTeam) => healthTeam.resumeByHealthTeam.HealthTeamType === HealthTeamTypeEnum.EAP
		)
		.map((resource) => resource.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.value)
		.reduce((acc, curr) => acc + curr, 0);
	const expectedResourceReceivable = resumeFinalByHealthTeam
		.map((resource) => resource.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.value)
		.reduce((acc, curr) => acc + curr, 0);

	const totalHealthTeamESF = resumeFinalByHealthTeam.filter(
		(healthTeam) => healthTeam.resumeByHealthTeam.HealthTeamType === HealthTeamTypeEnum.ESF
	).length;
	const totalHealthTeamEAP = resumeFinalByHealthTeam.filter(
		(healthTeam) => healthTeam.resumeByHealthTeam.HealthTeamType === HealthTeamTypeEnum.EAP
	).length;

	const achievableValue = totalHealthTeamESF * 8000 + totalHealthTeamEAP * 3000;

	const totalHealthTeamExcellent = resumeFinalByHealthTeam.filter(
		(healthTeam) =>
			healthTeam.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.classification ===
			"Ótimo"
	).length;
	const totalHealthTeamGood = resumeFinalByHealthTeam.filter(
		(healthTeam) =>
			healthTeam.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.classification ===
			"Bom"
	).length;
	const totalHealthTeamSufficient = resumeFinalByHealthTeam.filter(
		(healthTeam) =>
			healthTeam.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.classification ===
			"Suficiente"
	).length;
	const totalHealthTeamFair = resumeFinalByHealthTeam.filter(
		(healthTeam) =>
			healthTeam.resultsPaymentByHealthTeam.resourceEvaluatedWithPenalty.classification ===
			"Regular"
	).length;

	async function handleTerritorialCitizenFinalEvaluationResultResumeDashboard() {
		return await GetTerritorialCitizenFinalEvaluationResultResumeDashboard({
			ibgeCode: ibgeCode,
			cnes: cnes,
			uf: uf,
			quarter: selectedQuarter.value,
			ine: selectedHealthTeam?.value,
		});
	}

	async function fetch() {
		const cacheKey = `[resource-evaluation-dimension-dashboard][${uf}][${ibgeCode}][${cnes}][${selectedQuarter.value}][${selectedHealthTeam?.value}]`;
		setIsLoading(true);
		setTerritorialCitizenFinalEvaluatedResume(
			await fetchWithCache(
				cacheKey,
				getExpirationDateMidnight(),
				handleTerritorialCitizenFinalEvaluationResultResumeDashboard
			)
		);
		setIsLoading(false);
	}

	async function handleCityPopulationClassification() {
		const cityPopulactionClassification = await GetCityPopulationClassification({
			cityId: currentAccount.id,
			quarter: selectedQuarter.value,
		});
		return citiesPopulationsClassifications.find(
			(city) =>
				city.cityPopulationClassification ===
				cityPopulactionClassification.cityPopulationClassificationId
		);
	}

	async function fetchSizeHistory() {
		setIsLoadingCityClassificationHistory(true);
		setCityPopulationClassification(await handleCityPopulationClassification());
		setIsLoadingCityClassificationHistory(false);
	}

	useEffect(() => {
		fetchSizeHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuarter]);

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoadingScreen loading={isLoading} />
			<Row className="bg-white d-flex justify-content-between mx-3 p-2 gap-1">
				<Col sm={12} md={4} lg={4} xl={4}>
					<QuartersSelect
						value={selectedQuarter}
						onChange={(newValue) => setSelectedQuarter(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={12} md={4} lg={4} xl={4}>
					<HealthTeamsSelect
						healthTeams={healthTeams}
						value={selectedHealthTeam}
						onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={2} md={2} lg={2} xl={2} className="d-flex align-items-end">
					<Button
						onClick={() => {
							fetch();
						}}
					>
						Consultar
					</Button>
				</Col>
			</Row>
			<Row className="m-3">
				<Card>
					<Card.Header className="d-flex justify-content-between ps-2">
						<Card.Title className="text-primary rounded gap-2">
							<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
								<BsClipboardData />
							</label>
							Avaliação final
						</Card.Title>
						{!isLoadingCityClassificationHistory &&
							cityPopulationClassification?.cityPopulationClassification && (
								<Card.Title>
									<strong>Porte do municipio:</strong>
									<div className="ms-2">
										{`${cityPopulationClassification?.cityPopulationClassification} (${cityPopulationClassification?.description})`}
									</div>
								</Card.Title>
							)}
					</Card.Header>
				</Card>
			</Row>
			<Row className="m-3">
				<ResourceEvaluationDimensionCards
					totalPaymentByEsf={totalPaymentByEsf}
					totalPaymentByEAP={totalPaymentByEap}
					totalPaymentAchievable={achievableValue}
					totalPayment={expectedResourceReceivable}
				/>
			</Row>
			<Row className="m-3">
				<Col sm={12} md={12} lg={6} xl={6}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title>Resultado da previsão</Card.Title>
						</Card.Header>
						<Card.Body className="h-100 w-100 d-flex justify-content-center">
							<DonutChart
								labels={["Valor previsto à receber", "Valor alcançavél "]}
								textLabel="R$"
								colors={["#008FFB", "#00E296"]}
								series={[
									expectedResourceReceivable,
									achievableValue - expectedResourceReceivable,
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title>Total da classificação</Card.Title>
						</Card.Header>
						<Card.Body className="h-100 w-100">
							<PieChart
								categories={["Ótimo", "Bom", "Suficiente", "Regular"]}
								colors={["#008FFB", "#00E296", "#FFD83F", "#F1416C"]}
								series={[
									totalHealthTeamExcellent,
									totalHealthTeamGood,
									totalHealthTeamSufficient,
									totalHealthTeamFair,
								]}
								height={200}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="m-3">
				<Card>
					<Card.Header>
						<Card.Title>Resultado final por tipo de equipe</Card.Title>
					</Card.Header>
					<Card.Body className="p-2 overflow-auto" style={{ maxHeight: "50rem" }}>
						<TableResourceEvaluatedByHealthTeam
							resumeDashboardEvaluatedDimension={resumeDashboard}
							cityPopulationClassification={cityPopulationClassification}
							isLoading={isLoading}
						/>
					</Card.Body>
				</Card>
			</Row>
		</div>
	);
}
