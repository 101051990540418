import { ResumeGrouped } from "@/services/esus/attendanceService";
import { HealthTeamTypeEnum } from "./enums/HealthTeamTypeEnum";
import { CityPopulationClassification } from "./cityPopulationClasssification";
import { VulnerableCitizenEnum } from "./enums/VulnerableCitizenEnum";
import { TerritorialCitizenRegisterStatusEnum } from "./enums/TerritorialCitizenRegisterStatus";
import {
	calculateAccompanimentDimensionResult,
	calculateRegistrationDimensionResult,
	getResourceEvaluatedByResult,
	getScoreDimensionAccompaniment,
	getScoreDimensionRegister,
} from "./resourcesEvaluatedByResult";
import { ClassificationResourceEvaluated } from "@/@types/esus/ClassificationResourceEvaluated";

export function filterByVulnerability(
	territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume: ResumeGrouped<
		boolean,
		ResumeGrouped<VulnerableCitizenEnum, object>
	>[],
	isAccompaniment: boolean,
	key: keyof typeof VulnerableCitizenEnum
) {
	const vulnerableSeries =
		(territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamResume || [])
			.filter((groupedHealthTeam) => groupedHealthTeam.key === isAccompaniment)
			.map(
				(groupedVulnerableStatus) =>
					groupedVulnerableStatus.values?.find(
						(vulnerableStatus) => vulnerableStatus.key === VulnerableCitizenEnum[key]
					)?.total || 0
			) || [];

	return vulnerableSeries;
}

export function calculatePaymentByHealthTeam(
	healthTeamType: HealthTeamTypeEnum,
	cityPopulationClassification: CityPopulationClassification,
	territorialCitizenRegistrationByHealthTeamResume: ResumeGrouped<number, object>[],
	territorialCitizenAccompanimentByhealthTeamResume: ResumeGrouped<
		boolean,
		ResumeGrouped<VulnerableCitizenEnum, object>
	>[],
	parameter: number
) {
	const totalFciFinancedByHealthTeam = territorialCitizenRegistrationByHealthTeamResume
		?.filter(
			(registerStatus) =>
				registerStatus.key ===
					TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED ||
				registerStatus.key ===
					TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED
		)
		.map((financedTotal) => financedTotal?.total)
		.reduce((acc, curr) => (acc || 0) + (curr || 0), 0);
	const totalRegisterCompletedByHealthTeam = territorialCitizenRegistrationByHealthTeamResume
		?.filter(
			(registerStatus) =>
				registerStatus.key === TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED
		)
		.map((financedTotal) => financedTotal?.total)
		.reduce((acc, curr) => (acc || 0) + (curr || 0), 0);

	const registrationDimensionResult = calculateRegistrationDimensionResult(
		totalFciFinancedByHealthTeam,
		totalRegisterCompletedByHealthTeam,
		parameter
	);

	const scoreDimensionRegister = getScoreDimensionRegister(registrationDimensionResult);

	const vulnerableATotal = filterByVulnerability(
		territorialCitizenAccompanimentByhealthTeamResume || [],
		true,
		"NO_CRITERION"
	).reduce((acc, curr) => acc + curr, 0);

	const vulnerableBTotal = filterByVulnerability(
		territorialCitizenAccompanimentByhealthTeamResume || [],
		true,
		"OLD_PEOPLE_AND_CHILDREN"
	).reduce((acc, curr) => acc + curr, 0);

	const vulnerableCTotal = filterByVulnerability(
		territorialCitizenAccompanimentByhealthTeamResume || [],
		true,
		"CITIZENS_RECEIVING_SOCIAL_BENEFITS"
	).reduce((acc, curr) => acc + curr, 0);

	const vulnerableDTotal = filterByVulnerability(
		territorialCitizenAccompanimentByhealthTeamResume ?? [],
		true,
		"BOTH_CRITERIA"
	).reduce((acc, curr) => acc + curr, 0);

	const totalCitizensLinked = territorialCitizenAccompanimentByhealthTeamResume
		.map((item) => item.total)
		.reduce((acc, curr) => acc + curr, 0);

	const hasPenalty =
		(healthTeamType === HealthTeamTypeEnum.ESF
			? cityPopulationClassification?.parameter?.esf?.max || 0
			: healthTeamType === HealthTeamTypeEnum.EAP
			? cityPopulationClassification?.parameter?.eap20?.max || 0
			: 0) <= totalCitizensLinked;

	const accompanimentDimensionResult = calculateAccompanimentDimensionResult(
		vulnerableATotal,
		vulnerableBTotal,
		vulnerableCTotal,
		vulnerableDTotal,
		parameter
	);

	const scoreDimensionAccompaniment = getScoreDimensionAccompaniment(
		accompanimentDimensionResult
	);

	const scoreFinal = scoreDimensionRegister.value + scoreDimensionAccompaniment.value;

	const resultFinalWithPenalty = getResourceEvaluatedByResult(
		scoreFinal,
		hasPenalty,
		healthTeamType
	) as ClassificationResourceEvaluated;

	const resultFinalWithoutPenalty = getResourceEvaluatedByResult(
		scoreFinal,
		false,
		healthTeamType
	) as ClassificationResourceEvaluated;

	return {
		resourceEvaluatedWithPenalty: resultFinalWithPenalty,
		resourceEvaluatedWithoutPenalty: resultFinalWithoutPenalty,
		scoreRegisterX: scoreDimensionRegister,
		scoreAccompanimentY: scoreDimensionAccompaniment,
		finalScore: scoreFinal,
	};
}
