import { CityPopulationClassificationEnum } from "./enums/CityPopulationClassificationEnum";
import { HealthTeamTypeEnum } from "./enums/HealthTeamTypeEnum";

export type CityPopulationClassification = {
	cityPopulationClassification: CityPopulationClassificationEnum;
	description: string;
	parameter: {
		esf: {
			code: HealthTeamTypeEnum;
			min: number;
			max: number;
		};
		eap30: {
			code: HealthTeamTypeEnum;
			min: number;
			max: number;
		};
		eap20: {
			code: HealthTeamTypeEnum;
			min: number;
			max: number;
		};
	};
};

export const citiesPopulationsClassifications: CityPopulationClassification[] = [
	{
		cityPopulationClassification: CityPopulationClassificationEnum.UP_TO_20K,
		description: "Até 20 mil",
		parameter: {
			esf: {
				code: HealthTeamTypeEnum.ESF,
				min: 2000,
				max: 3000,
			},
			eap30: {
				code: HealthTeamTypeEnum.EAP,
				min: 1500,
				max: 2250,
			},
			eap20: {
				code: HealthTeamTypeEnum.EAP,
				min: 1000,
				max: 1500,
			},
		},
	},
	{
		cityPopulationClassification: CityPopulationClassificationEnum["20K_TO_50K"],
		description: "20 mil até 50 mil",
		parameter: {
			esf: {
				code: HealthTeamTypeEnum.ESF,
				min: 2500,
				max: 3750,
			},
			eap20: {
				code: HealthTeamTypeEnum.EAP,
				min: 1875,
				max: 2812.5,
			},
			eap30: {
				code: HealthTeamTypeEnum.EAP,
				min: 1250,
				max: 1875,
			},
		},
	},
	{
		cityPopulationClassification: CityPopulationClassificationEnum["50k_TO_100K"],
		description: "50 mil até 100 mil",
		parameter: {
			esf: {
				code: HealthTeamTypeEnum.ESF,
				min: 2750,
				max: 4125,
			},
			eap20: {
				code: HealthTeamTypeEnum.EAP,
				min: 2063,
				max: 3094.5,
			},
			eap30: {
				code: HealthTeamTypeEnum.EAP,
				min: 1375,
				max: 2062.5,
			},
		},
	},
	{
		cityPopulationClassification: CityPopulationClassificationEnum.OVER_100K,
		description: "Acima de 100 mil",
		parameter: {
			esf: {
				code: HealthTeamTypeEnum.ESF,
				min: 3000,
				max: 4500,
			},
			eap20: {
				code: HealthTeamTypeEnum.EAP,
				min: 2250,
				max: 3375,
			},
			eap30: {
				code: HealthTeamTypeEnum.EAP,
				min: 1500,
				max: 2250,
			},
		},
	},
];
