import { useEffect, useState } from "react";

import Nav from "react-bootstrap/Nav";

import { useLayout } from "@/../../src/_metronic/layout/core";

import { RegistrationDimension } from "./RegistrationDimension";
import { AccompanimentDimension } from "./AccompanimentDimension";
import { ResourceEvaluationDimension } from "./ResourceEvaluationDimension";

export function TerritorialCitizensRegistrationDashboard() {
	const { setTitle } = useLayout();
	const [optionMenu, setOptionMenu] = useState(1);

	function renderPanel() {
		switch (optionMenu) {
			case 1:
				return <RegistrationDimension />;
			case 2:
				return <AccompanimentDimension />;
			case 3:
				return <ResourceEvaluationDimension />;
		}
	}

	useEffect(() => {
		setTitle("Dashboard de vinculo e acompanhamento");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ minWidth: "320px" }}>
			<section>
				<div className="d-flex align-items-stretch w-full mx-3 p-0 h-60px h-lg-70px overflow-auto mb-lg-0">
					<Nav
						fill
						variant="tabs"
						defaultActiveKey="/acompanhamento-territorial/cadastros"
						className="nav nav-stretch flex-nowrap w-100 h-100"
					>
						<Nav.Item onClick={() => setOptionMenu(1)}>
							<Nav.Link
								eventKey="/acompanhamento-territorial/cadastros"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Dimensão cadastro
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">
										Monitoramento
									</span>
								</div>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={() => setOptionMenu(2)}>
							<Nav.Link
								eventKey="link-2"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Dimensão acompanhamento
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">Controle</span>
								</div>
							</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={() => setOptionMenu(3)}>
							<Nav.Link
								eventKey="link-3"
								className="nav-item flex-equal cursor-pointer"
							>
								<div className="d-flex flex-column text-nowrap flex-center w-100">
									<span className="text-uppercase text-gray-900 fw-bolder fs-6 fs-lg-5">
										Avaliação final
									</span>
									<span className="text-gray-500 fs-8 fs-lg-7">Resultado</span>
								</div>
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</div>
			</section>
			{renderPanel()}
		</div>
	);
}
