import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";

import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";

import { BarChart, ChartAnnotation } from "@/components/Charts/BarChart";

type healthTeamChartData = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
	annotations?: ChartAnnotation[];
};

type Props = {
	title: string;
	keyMenu: string | null;
	setKeyMenu: (event: string | null) => void;
	data: healthTeamChartData;
	chartColors: string[];
	isLoading?: boolean;
};

export function TeamPerformanceCharts({
	keyMenu,
	setKeyMenu,
	data,
	title,
	chartColors,
	isLoading,
}: Props) {
	return (
		<Card>
			<Card.Header>
				<Card.Title>{title}</Card.Title>
				<Nav
					fill
					variant="tabs"
					className="d-flex align-items-center"
					activeKey={keyMenu!}
					onSelect={(event) => setKeyMenu(event)}
				>
					<Nav.Item>
						<Nav.Link
							eventKey={HealthTeamTypeEnum.ESF}
							className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
						>
							ESF
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey={HealthTeamTypeEnum.EAP}
							className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
						>
							EAP
						</Nav.Link>
					</Nav.Item>
				</Nav>
			</Card.Header>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="h-100 w-100" style={{ maxHeight: "50rem", overflowY: "auto" }}>
					<BarChart
						series={data.series}
						categories={data.categories}
						annotations={data.annotations}
						colors={chartColors}
						categoryTextWidth={200}
						paddingRight={50}
						isLoading={isLoading}
						isTotalDatalabelEnable
						isStacked
					/>
				</div>
			</Card.Body>
		</Card>
	);
}
