import { Vaccination } from "@/@types/esus/Vaccination";
import { get } from "../api";
import { Paginated } from "@/@types/generics/paginated";
import { Citizen } from "@/@types/esus/Citizen";

export type TripleViralResumeType = {
	identified: number;
	finished: number;
	pending: number;
	delayed: number;
	abandoned: number;
	lost: number;
	totalD1: number;
	totalD2: number;
	totalDU: number;
};

export type CitizenVaccinations = {
	citizen: Citizen & {
		area?: string;
		phone?: string;
	};
	statusD1: boolean;
	statusD2: boolean;
	statusDU: boolean;
	vaccinationStatus: "finished" | "pending" | "delayed" | "abandoned" | "lost";
	vaccinations: Vaccination[];
};

type VaccinationHistoryParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	citizenExtraId: number;
};

type VaccinationParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	cnes: string;
	ine: string;
};

type VaccinationPaginatedParams = {
	uf: string;
	ibgeCode: string;
	startDate: string;
	endDate: string;
	cnes: string;
	ine: string;
	fullName: string;
	vaccinationStatus: string;
	pageNumber: number;
	pageSize?: number;
};

export async function getVaccinationHistory({ uf, ibgeCode, ...params }: VaccinationHistoryParams) {
	const response = await get<Vaccination[]>(
		`/uf/${uf}/city/${ibgeCode}/Vaccination/VaccinationHistory`,
		params
	);
	return response.data || [];
}

export async function getTripleViralResume({ uf, ibgeCode, ...params }: VaccinationParams) {
	const response = await get<TripleViralResumeType>(
		`/uf/${uf}/city/${ibgeCode}/vaccination/ChildTripleViralResume`,
		params
	);
	return response.data || ({} as TripleViralResumeType);
}

export async function getTripleViralCitizensVaccinationsPaginated({
	uf,
	ibgeCode,
	...params
}: VaccinationPaginatedParams) {
	const response = await get<Paginated<CitizenVaccinations>>(
		`/uf/${uf}/city/${ibgeCode}/vaccination/childtripleviralvaccinations`,
		params
	);
	return response.data || ({} as Paginated<CitizenVaccinations>);
}
