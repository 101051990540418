import { HealthTeam } from "@/@types/esus/HealthTeam";
import { HealthUnit } from "@/@types/esus/HealthUnit";
import { Professional } from "@/@types/esus/Professional";
import { get } from "../api";
import { Paginated } from "@/@types/generics/paginated";
import { Citizen } from "@/@types/esus/Citizen";
import { ResumeGrouped } from "./attendanceService";
import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";
import { PaginationFilter } from "@/@types/generics/genericals";
import { TerritorialCitizenRegisterStatusEnum } from "@/utils/enums/TerritorialCitizenRegisterStatus";
import { VulnerableCitizenEnum } from "@/utils/enums/VulnerableCitizenEnum";
import { Immunobiological } from "@/@types/esus/Immunobiological";
import { ImmunobiologicalDosage } from "@/@types/esus/ImmunobiologicalDosage";
import { CitizenExtra } from "@/@types/esus/CitizenExtra";
import { TerritorialCitizenAccompanimentEnum } from "@/utils/enums/TerritorialCitizenAccompanimentEnum";

export type TerritorialCitizenFinalEvaluationResultResume = {
	territorialCitizenRegistrationDashboardResume?: TerritorialCitizenRegistrationResume;
	territorialCitizenAccompanimentDashboardResume?: TerritorialCitizenAccompanimentResume;
};

export type TerritorialCitizenRegistrationResume = {
	resumeByRegisterStatus?: ResumeGrouped<number, object>[];
	territorialCitizenRegisterByPeriodAndGroupedByhealthTeamEAPResume?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<number, object>
	>[];
	territorialCitizenRegisterByPeriodAndGroupedByhealthTeamESFResume?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<number, object>
	>[];
};

export type TerritorialCitizenAccompanimentResume = {
	accompanimentResume?: {
		total?: number;
		totalAccompanied?: number;
		totalPeopleWithNoContacts?: number;
		totalPeopleWithOneContact?: number;
		totalPeopleWithTwoOrMoreContacts?: number;
		totalFastRegister?: number;
	};
	classificatonByAccompanimentResume?: {
		noCriteriaSeriesTotal?: number;
		oldPeopleAndChildrenSeriesTotal?: number;
		citizensReceivingSocialBenefitsSeriesTotal?: number;
		bothCriteriaSeriesTotal?: number;
	};
	territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamESFResume?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>
	>[];
	territorialCitizenAccompanimentByPeriodAndGroupedByhealthTeamEAPResume?: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<boolean, ResumeGrouped<VulnerableCitizenEnum, object>>
	>[];
};

export type TerritorialCitizenResume = {
	totalCitizens: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherBuildings: number;
	totalAcs: number;
};

export type TerritorialCitizenRegister = {
	citizen: Citizen & Partial<CitizenExtra>;
	accompaniedCitizenClassification?: VulnerableCitizenEnum;
	territorialCitizenAssitenceContacts?: {
		date: string;
		healthUnit?: HealthUnit;
		healthTeam?: HealthTeam;
		attendanceType: TerritorialCitizenAccompanimentEnum;
	}[];
	vaccinations?: {
		citizenId?: number;
		vaccine?: {
			dateofVaccine: string;
			healthTeam?: HealthTeam;
			healthUnit?: HealthUnit;
			immunobiological: Immunobiological;
			immunobiologicalDosage: ImmunobiologicalDosage;
			professional: {
				cns: string;
				name: string;
			};
		};
	}[];
	hasBenefits: boolean;
	isAccompanied: boolean;
	isChildOrOlder: boolean;
	isIdentified: boolean;
	registerStatus?: TerritorialCitizenRegisterStatusEnum;
	individualRecordStatus?: string;
	residenceFamilyRecordStatus?: string;
	lastIndividualRecord?: {
		registerDate: string;
		professional: Professional;
		healthUnit: HealthUnit;
		healthTeam: HealthTeam;
	};
	lastResidenceFamilyRecord?: {
		registerDate: string;
		professional: Professional;
		healthUnit: HealthUnit;
		healthTeam: HealthTeam;
	};
};

export type ResumeHealthUnit = {
	healthUnit: HealthUnit;
	total: number;
};

export type ResumeHealthTeam = {
	healthTeam: HealthTeam;
	total: number;
};

export type ResumeByProfessional = {
	professional: Professional;
	total: number;
};

export type ResumeByPeriod = {
	period: {
		year: number;
		month: number;
	};
	total: number;
};

type TerritorialCitizenResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

type TerritorialCitizenResumeByPeriodParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
};

type ComponentDashboardTerritorialRegistersParams = {
	uf: string;
	ibgeCode: string;
	quarter: string;
	cnes: string;
	ine?: string;
	fullName?: string;
	healthTeamType?: HealthTeamTypeEnum | null;
	citizenRegisterStatus?: TerritorialCitizenRegisterStatusEnum | null;
	vulnerableCitizenStatus?: VulnerableCitizenEnum | null;
	microArea?: string | null;
};

export async function getTerritorialCitizenResume({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<TerritorialCitizenResume>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/getResume`,
		params
	);
	return response.data || ({} as TerritorialCitizenResume);
}

export async function GetTerritorialCitizenRegistration({
	uf,
	ibgeCode,
	...params
}: ComponentDashboardTerritorialRegistersParams & PaginationFilter) {
	const response = await get<Paginated<TerritorialCitizenRegister>>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetTerritorialCitizenRegistration`,
		params
	);
	return response.data || ({} as Paginated<TerritorialCitizenRegister>);
}

export async function GetTerritorialCitizenRegistrationReport({
	uf,
	ibgeCode,
	...params
}: ComponentDashboardTerritorialRegistersParams) {
	const response = await get<TerritorialCitizenRegister[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetTerritorialCitizenRegistrationReport`,
		params
	);
	return response.data || [];
}

export async function GetTerritorialCitizenRegistrationResume({
	uf,
	ibgeCode,
	...params
}: ComponentDashboardTerritorialRegistersParams) {
	const response = await get<TerritorialCitizenRegistrationResume>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetTerritorialCitizenRegistrationResume`,
		params
	);
	return response.data || ({} as TerritorialCitizenRegistrationResume);
}

export async function GetTerritorialCitizensAccompanimentResume({
	uf,
	ibgeCode,
	...params
}: ComponentDashboardTerritorialRegistersParams) {
	const response = await get<TerritorialCitizenAccompanimentResume>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetTerritorialCitizensAccompanimentResume`,
		params
	);
	return response.data || ({} as TerritorialCitizenAccompanimentResume);
}

export async function GetTerritorialCitizenFinalEvaluationResultResumeDashboard({
	uf,
	ibgeCode,
	...params
}: ComponentDashboardTerritorialRegistersParams) {
	const response = await get<TerritorialCitizenFinalEvaluationResultResume>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetTerritorialFinalEvaluationResultResumeDashboard`,
		params
	);
	return response.data || ({} as TerritorialCitizenFinalEvaluationResultResume);
}

export async function getResumeByHealthUnits({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthUnit[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByHealthUnit`,
		params
	);
	return response.data || [];
}

export async function getResumeByHealthTeams({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthTeam[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByHealthTeam`,
		params
	);
	return response.data || [];
}

export async function getResumeByProfessionals({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeByProfessional[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByProfessional`,
		params
	);
	return response.data || [];
}

export async function getResumeCitizensUpdatedByPeriod({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeByPeriodParams) {
	const response = await get<ResumeByPeriod[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeCitizensUpdatedByPeriod`,
		params
	);
	return response.data || [];
}

export async function getResumeNewCitizensByPeriod({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeByPeriodParams) {
	const response = await get<ResumeByPeriod[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeNewCitizensByPeriod`,
		params
	);
	return response.data || [];
}

export async function getTotalCitizens({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams & { ine: string }) {
	const response = await get<number>(
		`/uf/${uf}/city/${ibgeCode}/territorialCitizen/getTotalCitizens`,
		params
	);
	return response.data || 0;
}
