import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import { FaUser } from "react-icons/fa";

import {
	handleIndicator2,
	handleIndicator2Resume,
	IndicatorResume,
	PregnantPatient,
} from "@/services/esus/indicatorService";
import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate, indicatorsDetail } from "@/utils/indicators";
import { IndicatorType } from "@/utils/enums/indicatorType";
import { exportExcel } from "@/utils/exports";
import { Indicators } from "@/utils/enums/indicators";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { Paginated } from "@/@types/generics/paginated";
import { Option } from "@/@types/generics/Option";
import { GenericObject, SelectOptionsProps } from "@/@types/generics/genericals";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { InformationIndicatorCard } from "@/components/InformationIndicatorCard";
import { LoadingScreen } from "@/components/LoadingScreen";
import { TableIndicator2 } from "./TableIndicator2";
import { FiltersIndicator2 } from "./FiltersIndicator2";
import { ReportIndicator2 } from "./ReportIndicator2";

type Props = {
	selectedUnit: SelectOptionsProps;
	selectedHealthTeam: SelectOptionsProps;
	selectedQuarter: SelectOptionsProps;
};

export function Indicator2({ selectedUnit, selectedHealthTeam, selectedQuarter }: Props) {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const { setTitle } = useLayout();
	const { indicator2 } = indicatorsDetail;
	const [indicatorResume, setIndicatorResume] = useState<IndicatorResume>();
	const [selectedIndicatorTableType, setSelectedIndicatorTableType] = useState<
		Option<IndicatorType | null>
	>({ value: null, label: "Todos" } as Option<IndicatorType | null>);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const printPageRef = useRef<HTMLDivElement | null>(null);
	const [pregnantPatient, setPregnantPatient] = useState<Paginated<PregnantPatient>>(
		{} as Paginated<PregnantPatient>
	);
	const [searchBirthdateCitizen, setSearchBirthdateCitizen] = useState("");
	const debouncedSearchBirthdateCitizen = useDebounce(searchBirthdateCitizen, 1000);
	const [searchFullNameCitizen, setSearchFullNameCitizen] = useState("");
	const debouncedFullName = useDebounce(searchFullNameCitizen, 2000);
	const cacheExpirationDate = dayjs()
		.add(dayjs(dayjs().add(1, "day").format("YYYY-MM-DD")).diff(dayjs(), "minutes"), "minutes")
		.format();

	async function fetchIndicator(pageSize?: number) {
		return handleIndicator2({
			uf,
			ibgeCode,
			quarter: selectedQuarter.value,
			indicatorType: selectedIndicatorTableType.value,
			cnes: cnes || selectedUnit.value,
			ine: selectedHealthTeam.value,
			searchBirthDateCitizen: searchBirthdateCitizen,
			searchCitizenFullName: searchFullNameCitizen,
			pageNumber,
			pageSize: pageSize || 10,
		});
	}

	async function handleExportTable() {
		setShowLoadingScreen(true);
		const response = await fetchIndicator(pregnantPatient?.totalRecords);
		setShowLoadingScreen(false);

		if (Object.keys(response?.data || []).length) {
			const excelData: GenericObject[] = transformPregnantPatientsToExportExcel(
				response?.data
			);
			exportExcel(excelData, `Tabela`);
		}
	}

	function transformPregnantPatientsToExportExcel(pregnantPatients: PregnantPatient[]) {
		return pregnantPatients.map(
			(pregnantPatient) =>
				({
					Sinc: ["PEC", "PEC", "SISAB", "SINCRONIZADO"][
						pregnantPatient.preliminaryReportStatus
					],
					Nome: pregnantPatient.fullName,
					"Data de Nascimento": dayjs(pregnantPatient.birthdate).format("DD/MM/YYYY"),
					"Cpf/Cns": identifierOrNationalHealthCard(pregnantPatient),
					Equipe: pregnantPatient.healthTeam?.name || "N/A",
					"Micro área": pregnantPatient.area,
					"Data Última Menstruação": displayFirstDumDate(pregnantPatient.firstDumDate),
					"1º Atendimento": pregnantPatient.firstAttendanceDate
						? dayjs(pregnantPatient.firstAttendanceDate).format("DD/MM/YYYY")
						: "",
					"Último Atendimento": pregnantPatient.lastAttendanceDate
						? dayjs(pregnantPatient.lastAttendanceDate).format("DD/MM/YYYY")
						: "",
					"Nº de consultas": pregnantPatient.attendances.length,
					IG: pregnantPatient.gestationalAge1St,
					DPP: dayjs(pregnantPatient.dppDate).format("DD/MM/YYYY"),
				} as GenericObject)
		);
	}

	async function fetch() {
		const cacheKey = `[${uf}][${ibgeCode}][${selectedQuarter.value}][${Indicators.PregnantWomenTestedForSyphilisAndHIV}]:${selectedIndicatorTableType.value}&${searchFullNameCitizen}&${searchBirthdateCitizen}&${selectedHealthTeam.value}&${selectedUnit.value}&${pageNumber}`;
		setIsLoading(true);
		setPregnantPatient(
			await fetchWithCache<Paginated<PregnantPatient>>(
				cacheKey,
				cacheExpirationDate,
				fetchIndicator
			)
		);
		setIsLoading(false);
	}

	async function fetchResume() {
		const cacheKey = `[${uf}][${ibgeCode}][${selectedQuarter.value}][${Indicators.PregnantWomenTestedForSyphilisAndHIV}]:${selectedHealthTeam.value}&${selectedUnit.value}`;
		setIsLoadingResume(true);
		setIndicatorResume(
			await fetchWithCache<IndicatorResume>(cacheKey, cacheExpirationDate, async () => {
				return handleIndicator2Resume({
					uf,
					ibgeCode,
					quarter: selectedQuarter.value,
					cnes: cnes || selectedUnit.value,
					ine: selectedHealthTeam.value,
				});
			})
		);
		setIsLoadingResume(false);
	}

	const printPage = useReactToPrint({
		content: () => printPageRef.current,
	});

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		fetchResume();
		setPageNumber(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedHealthTeam, selectedQuarter, selectedUnit]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedHealthTeam,
		selectedQuarter,
		selectedUnit,
		debouncedFullName,
		debouncedSearchBirthdateCitizen,
		selectedIndicatorTableType,
	]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("PAINEL DE INDICADORES");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoadingScreen
				loading={showLoadingScreen}
				messages={[
					"Já estamos processando a geração da tabela.",
					"Os pacientes já vão aparecer, aguarde só um momento.",
					"Está quase lá!",
				]}
			/>

			<InformationIndicatorCard
				isLoading={isLoadingResume}
				handlePrint={printPage}
				setSelectedIndicatorTableType={setSelectedIndicatorTableType}
				totalSeries={
					(indicatorResume?.valid || 0) +
					(indicatorResume?.pendingEligible || 0) +
					(indicatorResume?.pendingIneligible || 0)
				}
				totalValids={indicatorResume?.valid || 0}
				totalPendingEligible={indicatorResume?.pendingEligible || 0}
				totalPendingIneligible={indicatorResume?.pendingIneligible || 0}
				indicatorDetails={indicator2}
			/>
			<section className="mt-5">
				<Card
					className={`border border-3 border-${
						selectedIndicatorTableType.value === IndicatorType.Validated
							? "primary"
							: selectedIndicatorTableType.value === IndicatorType.PendingEligible
							? "warning"
							: selectedIndicatorTableType.value === IndicatorType.PendingIneligible
							? "danger"
							: "secondary"
					}`}
				>
					<Card.Header className="border-0 flex-nowrap d-flex justify-content-between no-print">
						<FiltersIndicator2
							handleExportTable={handleExportTable}
							searchBirthdate={searchBirthdateCitizen}
							searchCitizen={searchFullNameCitizen}
							indicatorType={selectedIndicatorTableType}
							setIndicatorType={setSelectedIndicatorTableType}
							setSearchBirthdate={setSearchBirthdateCitizen}
							setSearchCitizen={setSearchFullNameCitizen}
							isLoading={isLoading}
						/>
					</Card.Header>
					<Card.Body>
						<TableIndicator2 isLoading={isLoading} pregnantPatient={pregnantPatient} />
					</Card.Body>
					<Card.Footer className="no-print">
						{pregnantPatient.totalRecords ? (
							<PaginationLinks
								itemsPerPage={pregnantPatient.pageSize}
								totalPages={pregnantPatient.totalRecords}
								changeSelectedPage={handleChangePageNumber}
								pageNumber={pageNumber || 0}
							/>
						) : (
							pregnantPatient.totalRecords === 0 &&
							!isLoading && (
								<NoRecordsFeedback
									message={"Nenhum resultado encontrado."}
									icon={<FaUser />}
								/>
							)
						)}
					</Card.Footer>
				</Card>
			</section>
			<section title="relatório" className="print-container" ref={printPageRef}>
				<ReportIndicator2
					title={indicator2.description}
					healthTeam={selectedHealthTeam}
					healthUnit={selectedUnit}
					quarter={selectedQuarter}
				/>
				<div className="mt-20">
					<InformationIndicatorCard
						indicatorDetails={indicator2}
						isLoading={isLoading}
						setSelectedIndicatorTableType={setSelectedIndicatorTableType}
						totalSeries={
							(indicatorResume?.valid || 0) +
							(indicatorResume?.pendingEligible || 0) +
							(indicatorResume?.pendingIneligible || 0)
						}
						totalValids={indicatorResume?.valid || 0}
						totalPendingEligible={indicatorResume?.pendingEligible || 0}
						totalPendingIneligible={indicatorResume?.pendingIneligible || 0}
					/>
				</div>
			</section>
		</div>
	);
}
