import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { FaWhatsapp } from "react-icons/fa";

import { TerritorialCitizenRegister } from "@/services/esus/territorialCitizenService";
import { phoneFormat } from "@/utils/formatPhone";
import { phoneValidation } from "@/utils/phoneValidation";
import { TerritorialCitizenAccompanimentEnum } from "@/utils/enums/TerritorialCitizenAccompanimentEnum";

import { TimeLine, TimeLineContent } from "@/components/TimeLine";

type Props = ModalProps & {
	selectedTerritorialCitizenRegister?: TerritorialCitizenRegister;
};

export function ModalTerritorialCitizenRegisterDetail({
	selectedTerritorialCitizenRegister,
	...rest
}: Props) {
	const attendances = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter(
		(a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.INDIVIDUAL_ATTENDANCE
	);
	const attendanceProcedures = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter((a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.ATTENDANCE_PROCEDURE);
	const dentalAttendances = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter((a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.DENTAL_ATTENDANCE);
	const foodConsumptionMarkers = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter(
		(a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.FOOD_COMSUMPTION_MARKER
	);
	const homeVisits = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter((a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.HOME_VISIT);
	const vaccinations = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter((a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.VACCINATION);
	const groupActivities = (
		selectedTerritorialCitizenRegister?.territorialCitizenAssitenceContacts || []
	)?.filter((a) => a.attendanceType === TerritorialCitizenAccompanimentEnum.GROUP_ACTIVITY);
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">Atendimentos</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={6} lg={6} xl={6}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.fullName
									? selectedTerritorialCitizenRegister?.citizen?.fullName
									: "N/A"}
							</span>
						</Col>
						<Col sm={5} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.birthdate
									? dayjs(
											selectedTerritorialCitizenRegister?.citizen?.birthdate
									  ).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={5} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Idade: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.age ?? "N/A"}
							</span>
						</Col>
						<Col sm={12} md={3} lg={3} xl={3} className="d-flex">
							<span className="fw-bold fs-8 text-primary">Telefone: </span>

							{selectedTerritorialCitizenRegister?.citizen?.phone &&
								phoneValidation(
									selectedTerritorialCitizenRegister?.citizen.phone
								) && (
									<label className="d-flex fs-8 text-nowrap gap-2">
										{phoneFormat(
											"55" + selectedTerritorialCitizenRegister?.citizen.phone
										)}
										<a
											rel="noreferrer"
											href={`https://wa.me/55${selectedTerritorialCitizenRegister?.citizen.phone}`}
											target="_blank"
										>
											<div title="Contato">
												<FaWhatsapp className="text-success" />
											</div>
										</a>
									</label>
								)}
						</Col>
						<Col sm={5} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Bairro: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.neighborhood ?? "N/A"}
							</span>
						</Col>
						<Col sm={5} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Endereço: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.street ?? "N/A"}
							</span>
						</Col>
						<Col sm={5} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">Nº: </span>
							<span className="fs-8">
								{selectedTerritorialCitizenRegister?.citizen?.houseNumber ?? "N/A"}
							</span>
						</Col>
					</Row>
				</section>
				<Accordion>
					<Accordion.Item eventKey="0">
						<Accordion.Header>
							Atendimentos - {attendances?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Atendimentos"
								timeLineContents={attendances?.map<TimeLineContent>(
									(attendance, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{attendance?.healthTeam?.name?.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{attendance?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: attendance?.date
												? dayjs(attendance?.date).format("DD/MM/YYYY")
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="1">
						<Accordion.Header>
							Procedimentos - {attendanceProcedures?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Procedimentos"
								timeLineContents={attendanceProcedures?.map<TimeLineContent>(
									(attendanceProcedure, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{attendanceProcedure?.healthTeam?.name.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{attendanceProcedure?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: attendanceProcedure?.date
												? dayjs(attendanceProcedure?.date).format(
														"DD/MM/YYYY"
												  )
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="2">
						<Accordion.Header>Vacinação - {vaccinations?.length || 0}</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="vacinação"
								timeLineContents={vaccinations?.map<TimeLineContent>(
									(vaccination, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{vaccination?.healthTeam?.name?.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{vaccination?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: vaccination?.date
												? dayjs(vaccination?.date).format("DD/MM/YYYY")
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="3">
						<Accordion.Header>
							Atendimentos odontológicos - {dentalAttendances?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Atendimentos odontológicos"
								timeLineContents={dentalAttendances?.map<TimeLineContent>(
									(dentalAttendance, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{dentalAttendance?.healthTeam?.name.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{dentalAttendance?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: dentalAttendance?.date
												? dayjs(dentalAttendance?.date).format("DD/MM/YYYY")
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="4">
						<Accordion.Header>
							Marcadores de consumo alimentar - {foodConsumptionMarkers?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Marcadores de consumo alimentar"
								timeLineContents={foodConsumptionMarkers?.map<TimeLineContent>(
									(foodConsumptionMarker, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{foodConsumptionMarker?.healthTeam?.name.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{foodConsumptionMarker?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: foodConsumptionMarker?.date
												? dayjs(foodConsumptionMarker?.date).format(
														"DD/MM/YYYY"
												  )
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="5">
						<Accordion.Header>
							Atividades em grupo - {groupActivities?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Atividades em grupo"
								timeLineContents={groupActivities?.map<TimeLineContent>(
									(groupActivity, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{groupActivity?.healthTeam?.name.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{groupActivity?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: groupActivity?.date
												? dayjs(groupActivity?.date).format("DD/MM/YYYY")
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="6">
						<Accordion.Header>
							Visitas domiciliar - {homeVisits?.length || 0}
						</Accordion.Header>
						<Accordion.Body>
							<TimeLine
								timeLineTitle="Visitas domiciliar"
								timeLineContents={homeVisits?.map<TimeLineContent>(
									(homeVisit, index) => {
										return {
											content: (
												<section key={index}>
													<div>
														<p>
															<span className="fw-bolder fs-8">
																Equipe:{" "}
															</span>
															{homeVisit?.healthTeam?.name.toUpperCase() ||
																"N/A"}
														</p>
														<p>
															<span className="fw-bolder fs-8">
																Unidade:{" "}
															</span>
															{homeVisit?.healthUnit?.name?.toUpperCase() ||
																"N/A"}
														</p>
													</div>
												</section>
											),
											eventDate: homeVisit?.date
												? dayjs(homeVisit?.date).format("DD/MM/YYYY")
												: "N/A",
										};
									}
								)}
							/>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Modal.Body>
		</Modal>
	);
}
