import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useUnits } from "@/hooks/useUnits";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { phoneFormat } from "@/utils/formatPhone";
import { calculateDelayedVaccinationSearchActive } from "@/utils/calculateDelayedVaccinationSearchActive";
import { calculateStatusVaccinationSearchActive } from "@/utils/calculateStatusVaccinationSearchActive";
import { getVaccinationHistory } from "@/services/esus/vaccinationService";
import { ActiveOlderVaccination } from "@/@types/esus/ActiveOlderVaccination";
import { HealthUnit } from "@/@types/esus/HealthUnit";
import { Vaccination } from "@/@types/esus/Vaccination";
import { ActiveVaccineRegister } from "@/@types/esus/ActiveVaccineRegister";

import { VaccinationCard, VaccinationCardData } from "@/components/VaccinationCard";
import { LegendData } from "@/components/LegendData";

type Props = ModalProps & {
	selectedCitizenActiveOlderVaccination: ActiveOlderVaccination;
};

type CombinedRegisters = {
	vaccination: ActiveVaccineRegister;
	status: "vaccinated" | "onTime" | "pending" | "delayed";
};

export function ModalActiveOlderVaccinationSearch({
	selectedCitizenActiveOlderVaccination,
	...rest
}: Props) {
	const { units } = useUnits();
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [vaccinationData, setVaccinationData] = useState<Vaccination[]>([]);

	async function handleVaccinesHistory() {
		setIsLoading(true);
		const filterData = filterVaccinationBySearchVaccinal(
			await getVaccinationHistory({
				uf: uf,
				ibgeCode: ibgeCode,
				cnes: cnes,
				citizenExtraId: selectedCitizenActiveOlderVaccination.citizenExtraId,
			})
		);
		setVaccinationData(filterData);
		setIsLoading(false);
	}

	const vaccinationHistory = vaccinationData?.map<ActiveVaccineRegister>((vaccination) => {
		return {
			id: vaccination.id,
			vaccinationDays: dayjs().diff(dayjs(vaccination.dateofVaccine), "days"),
			immunobiologicalCod: Number(vaccination.immunobiological?.identifier),
			immunobiologicalDosageName: vaccination.immunobiologicalDosage?.acronym,
			immunobiologicalName: vaccination.immunobiological?.name,
			cnes: vaccination.healthUnit?.cnes,
		};
	});

	const combinedRegisters: CombinedRegisters[] = (vaccinationHistory || [])
		?.map<CombinedRegisters>((vaccination) => {
			return {
				vaccination,
				status: "vaccinated",
			};
		})
		.concat(
			(selectedCitizenActiveOlderVaccination.registers || [])?.map((vaccination) => {
				return {
					vaccination,
					status: calculateStatusVaccinationSearchActive(
						vaccination.appointedDays,
						vaccination.vaccinationDays
					),
				};
			})
		);

	const vaccinationsData = combinedRegisters?.map<VaccinationCardData>((register) => {
		return {
			id: register.vaccination.id,
			status: register.status,
			dosageId: register.vaccination.id,
			immunobiologicalDosage: {
				name: register.vaccination.immunobiologicalDosageName,
				acronym: register.vaccination.immunobiologicalDosageName,
			},
			dateofVaccine: dateOfVaccination(register),
			healthUnit:
				(units.find((unit) => unit.cnes === register.vaccination.cnes) as HealthUnit) ||
				({} as HealthUnit),
			immunobiological: {
				name: register.vaccination.immunobiologicalName,
				acronym: register.vaccination.immunobiologicalName,
				identifier: String(register.vaccination.immunobiologicalCod),
			},
			professional: {
				name: vaccineAdministered(register)?.professional?.name,
				cns: vaccineAdministered(register)?.professional?.cns,
				id: vaccineAdministered(register)?.professional?.id,
			},
		};
	});

	function vaccineAdministered(item: CombinedRegisters): Vaccination {
		const vaccination = vaccinationData?.find(
			(vaccine) =>
				vaccine.id === item.vaccination.id &&
				vaccine.immunobiological.name === item.vaccination.immunobiologicalName
		);

		return vaccination || ({} as Vaccination);
	}

	function dateOfVaccination(item: CombinedRegisters) {
		const vaccineDate = vaccineAdministered(item)?.dateofVaccine;

		if (item.status === "vaccinated" && vaccineDate) {
			return dayjs(vaccineDate).format("DD/MM/YYYY");
		}

		return calculateDelayedVaccinationSearchActive(
			item.vaccination.appointedDays,
			item.vaccination.vaccinationDays
		);
	}

	function filterVaccinationBySearchVaccinal(data: Vaccination[]) {
		return data.filter((vaccine) =>
			selectedCitizenActiveOlderVaccination.registers?.find(
				(immunobiologicalCod) =>
					immunobiologicalCod.immunobiologicalCod.toString() ===
					vaccine.immunobiological.identifier
			)
		);
	}

	useEffect(() => {
		if (rest.show) {
			handleVaccinesHistory();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rest.show]);

	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">
						Acompanhamento de vacinas ativas
					</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span>{selectedCitizenActiveOlderVaccination.fullName}</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Telefone: </span>
							<span>
								{phoneFormat(selectedCitizenActiveOlderVaccination?.phone || "")}
							</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span>
								{dayjs(selectedCitizenActiveOlderVaccination.birthdate).format(
									"DD/MM/YYYY"
								)}
							</span>
						</Col>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Endereço: </span>
							<span>{selectedCitizenActiveOlderVaccination.street ?? "-"}</span>
						</Col>
						<Col sm={2} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">Nº: </span>
							<span>{selectedCitizenActiveOlderVaccination.houseNumber ?? "-"}</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Bairro: </span>
							<span>{selectedCitizenActiveOlderVaccination.neighborhood ?? "-"}</span>
						</Col>
						<Col sm={4} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-8 text-primary">Cidade: </span>
							<span>{selectedCitizenActiveOlderVaccination.city ?? "-"}</span>
						</Col>
						<Col sm={2} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">UF: </span>
							<span>{selectedCitizenActiveOlderVaccination.uf ?? "-"}</span>
						</Col>
					</Row>
				</section>
				<LegendData
					titles={["Vacinado", "Pendente", "Atrasado"]}
					ClassNamesColors={["text-success", "text-warning", "text-danger"]}
				/>
				{!isLoading ? (
					<VaccinationCard vaccinations={vaccinationsData} />
				) : (
					<div className="text-center mt-3">
						<div className="spinner-border text-primary" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}
