import { useState } from "react";
import { FaEllipsisV, FaInfoCircle } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";
import { CityPopulationClassification } from "@/utils/cityPopulationClasssification";
import { CityPopulationClassificationEnum } from "@/utils/enums/CityPopulationClassificationEnum";
import { calculatePaymentByHealthTeam } from "@/utils/healthTeamQualification";
import { handleColumnToggle } from "@/utils/handleColumnTogle";
import { Column } from "@/@types/generics/Column";
import { ResumeDashboardEvaluatedDimension } from "@/pages/esus/TerritorialCitizensRegistration/TerritorialDashboard/ResourceEvaluationDimension";

import { TableSkeletons } from "@/components/TableSkeletons";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import formulaAccompanimentoImage from "@/../../src/_metronic/assets/formulas/accompanimentFormula.jpg";
import formulaRegistrationImage from "@/../../src/_metronic/assets/formulas/registrationFormula.jpg";

type Props = {
	resumeDashboardEvaluatedDimension: ResumeDashboardEvaluatedDimension;
	cityPopulationClassification?: CityPopulationClassification;
	isLoading: boolean;
};

type ColumnsTable = {
	ine: Column;
	healthTeam: Column;
	healthTeamType: Column;
	scoreDimensionRegistration: Column;
	scoreDimensionAccompaniment: Column;
	totalPopulationLinked: Column;
	totalPopulationLimit: Column;
	resultFinish: Column;
	classificationWithoutPenalty: Column;
	classificationWithPenalty: Column;
	resourceExpectedWithoutPenalty: Column;
	resourceExpectedWithPenalty: Column;
};

export function TableResourceEvaluatedByHealthTeam({
	resumeDashboardEvaluatedDimension,
	cityPopulationClassification,
	isLoading,
}: Props) {
	const [columns, setColumns] = useState<ColumnsTable>({
		ine: { title: "Ine", visible: false },
		healthTeam: { title: "Equipe", visible: true },
		healthTeamType: { title: "Tipo", visible: true },
		scoreDimensionRegistration: { title: "Escore dimensão cadastro", visible: false },
		scoreDimensionAccompaniment: { title: "Escore dimensão acompanhamento", visible: false },
		totalPopulationLinked: { title: "Cidadãos vinculados", visible: false },
		totalPopulationLimit: { title: "Teto", visible: false },
		resultFinish: { title: "Nota final", visible: true },
		classificationWithoutPenalty: { title: "Classificação sem penalidade", visible: false },
		classificationWithPenalty: { title: "Classificação com penalidade", visible: true },
		resourceExpectedWithoutPenalty: { title: "Previsto sem penalidade", visible: false },
		resourceExpectedWithPenalty: { title: "Previsto com pensalidade", visible: true },
	});

	function getClassificationColor(classification: string) {
		switch (classification) {
			case "Ótimo":
				return "primary";
			case "Bom":
				return "success";
			case "Suficiente":
				return "warning";
			case "Regular":
				return "danger";
		}
	}
	return (
		<Table className="bg-white" striped bordered hover responsive>
			<thead className="text-center fs-7">
				<tr className="fw-bolder">
					<th className={`${!columns.ine.visible && "d-none"}`}>Ine</th>
					<th className={`${!columns.healthTeam.visible && "d-none"}`}>Equipe</th>
					<th className={`${!columns.healthTeamType.visible && "d-none"}`}>Tipo</th>
					<th className={`${!columns.scoreDimensionRegistration.visible && "d-none"}`}>
						Escore Dimensão cadastro{" "}
						<CardOverlayTrigger
							icon={<FaInfoCircle />}
							children={
								<img
									src={formulaRegistrationImage}
									alt="formula de ponderação da dimensão acompanhamento"
								/>
							}
						/>
					</th>
					<th className={`${!columns.scoreDimensionAccompaniment.visible && "d-none"}`}>
						Escore Dimensão acompanhamento{" "}
						<CardOverlayTrigger
							message="A = População acompanhada sem critério ,B = População acompanhada idosa ou criança, C = População acompanhada beneficiária, D = População acompanhada idosa ou criança e beneficiária"
							icon={<FaInfoCircle />}
							children={
								<img
									src={formulaAccompanimentoImage}
									alt="formula de ponderação da dimensão acompanhamento"
								/>
							}
						/>
					</th>
					<th className={`${!columns.totalPopulationLinked.visible && "d-none"}`}>
						População vinculada
					</th>
					<th className={`${!columns.totalPopulationLimit.visible && "d-none"}`}>Teto</th>
					<th className={`${!columns.resultFinish.visible && "d-none"}`}>Nota final</th>
					<th className={`${!columns.classificationWithoutPenalty.visible && "d-none"}`}>
						Classificação sem penalidade
					</th>
					<th className={`${!columns.classificationWithPenalty.visible && "d-none"}`}>
						Classificação com Penalidade
					</th>
					<th
						className={`${!columns.resourceExpectedWithoutPenalty.visible && "d-none"}`}
					>
						R$ Previsto sem penalidade
					</th>
					<th className={`${!columns.resourceExpectedWithPenalty.visible && "d-none"}`}>
						R$ Previsto com penalidade
					</th>
					<th>
						<OverlayTrigger
							trigger="click"
							placement="bottom"
							overlay={
								<Popover>
									<Popover.Body>
										<Form className="d-flex flex-column gap-2">
											{Object.keys(columns).map((key, index) => (
												<Form.Check
													key={`column-${index}`}
													type="checkbox"
													label={columns[key as keyof ColumnsTable].title}
													checked={
														columns[key as keyof ColumnsTable].visible
													}
													onChange={() =>
														handleColumnToggle(
															key as keyof ColumnsTable,
															setColumns
														)
													}
												/>
											))}
										</Form>
									</Popover.Body>
								</Popover>
							}
							rootClose
						>
							<Button
								title="Exibição de colunas da tabela"
								variant="white"
								className="px-2"
								disabled={isLoading}
							>
								<FaEllipsisV />
							</Button>
						</OverlayTrigger>
					</th>
				</tr>
			</thead>
			<tbody className="fs-8">
				{!isLoading && resumeDashboardEvaluatedDimension ? (
					<>
						{resumeDashboardEvaluatedDimension.map((healthTeamResume, index) => {
							const result = calculatePaymentByHealthTeam(
								healthTeamResume.HealthTeamType,
								cityPopulationClassification ||
									({} as CityPopulationClassification),
								healthTeamResume.registerResumes,
								healthTeamResume.accompanimentResumes,
								cityPopulationClassification?.parameter.esf.min ??
									CityPopulationClassificationEnum.UP_TO_20K
							);
							return (
								<tr key={`item-${index}`}>
									<td className={`px-2 ${!columns.ine.visible && "d-none"}`}>
										{healthTeamResume.healthTeam.ine ?? ""}
									</td>
									<td className={`${!columns.healthTeam.visible && "d-none"}`}>
										{healthTeamResume.healthTeam.name ?? ""}
									</td>
									<td
										className={`text-center ${
											!columns.healthTeamType.visible && "d-none"
										}`}
									>
										{HealthTeamTypeEnum[healthTeamResume.HealthTeamType]}
									</td>
									<td
										className={`text-center fw-bolder ${
											!columns.scoreDimensionRegistration.visible && "d-none"
										} text-${getClassificationColor(
											result.scoreRegisterX.classification
										)}`}
									>
										{result.scoreRegisterX.value}
									</td>
									<td
										className={`text-center fw-bolder ${
											!columns.scoreDimensionAccompaniment.visible && "d-none"
										} text-${getClassificationColor(
											result.scoreAccompanimentY.classification
										)}`}
									>
										{result.scoreAccompanimentY.value}
									</td>
									<td
										className={`text-center ${
											!columns.totalPopulationLinked.visible && "d-none"
										}`}
									>
										{healthTeamResume.registerResumes
											.map((resume) => resume.total)
											.reduce((acc, curr) => acc + curr, 0)
											.toLocaleString()}
									</td>
									<td
										className={`text-center ${
											!columns.totalPopulationLimit.visible && "d-none"
										}`}
									>
										{healthTeamResume.HealthTeamType === HealthTeamTypeEnum.EAP
											? cityPopulationClassification?.parameter.eap20.max.toLocaleString() ||
											  ""
											: healthTeamResume.HealthTeamType ===
											  HealthTeamTypeEnum.ESF
											? cityPopulationClassification?.parameter.esf.max.toLocaleString() ||
											  ""
											: ""}
									</td>
									<td
										className={`text-center fw-bolder ${
											!columns.resultFinish.visible && "d-none"
										}`}
									>
										{result.finalScore}
									</td>
									<td
										className={`text-center ${
											!columns.classificationWithoutPenalty.visible &&
											"d-none"
										}`}
									>
										<Badge
											bg={`${getClassificationColor(
												result.resourceEvaluatedWithoutPenalty
													.classification
											)}`}
										>
											{result.resourceEvaluatedWithoutPenalty.classification}
										</Badge>
									</td>
									<td
										className={`text-center ${
											!columns.classificationWithPenalty.visible && "d-none"
										}`}
									>
										<Badge
											bg={`${getClassificationColor(
												result.resourceEvaluatedWithPenalty.classification
											)}`}
										>
											{result.resourceEvaluatedWithPenalty.classification}
										</Badge>
									</td>
									<td
										className={`text-center fw-bolder ${
											!columns.resourceExpectedWithoutPenalty.visible &&
											"d-none"
										}`}
									>
										R$
										{result.resourceEvaluatedWithoutPenalty.value.toLocaleString()}
									</td>
									<td
										className={`text-center fw-bolder ${
											!columns.resourceExpectedWithPenalty.visible && "d-none"
										}`}
									>
										R$
										{result.resourceEvaluatedWithPenalty.value.toLocaleString()}
									</td>
									<td></td>
								</tr>
							);
						})}
					</>
				) : (
					isLoading && (
						<TableSkeletons
							numberOfCells={
								Object.values(columns).reduce(
									(count, column) => count + (column?.visible === true ? 1 : 0),
									0
								) + 1
							}
						/>
					)
				)}
			</tbody>
		</Table>
	);
}
