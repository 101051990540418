import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import clsx from "clsx";
import Yup from "@/utils/mixins/yup";
import { toAbsoluteUrl } from "@/../_metronic/helpers";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";

const loginSchema = Yup.object().shape({
	identifier: Yup.string()
		.cpf("Digite um CPF válido")
		.min(14, "Digite um CPF válido")
		.required("CPF é obrigatório"),
	password: Yup.string()
		.min(3, "Mínimo de 3 caracteres")
		.max(50, "Máximo de 50 caracteres")
		.required("Senha é obrigatória"),
});

const initialValues = {
	identifier: "",
	password: "",
};

export function Login() {
	const [loading, setLoading] = useState(false);
	const [errorsMessageLoginUser, setErrorsMessageLoginUser] = useState(false);
	const { login } = useCurrentAccount();

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			const isLogged = await login(values.identifier, values.password);
			if (isLogged) {
				setLoading(false);
			} else {
				setSubmitting(false);
				setStatus("CPF ou senha inválidos");
				setErrorsMessageLoginUser(true);
			}
		},
	});

	useEffect(() => {
		setErrorsMessageLoginUser(false);
	}, [formik.values.identifier, formik.values.password]);

	return (
		<div>
			<div className="d-flex align-items-center justify-content-center">
				<img
					id="logo-multisus"
					src={toAbsoluteUrl("/media/logos/multisus-azul.png")}
					alt="Indicadores ESUS - Logo azul escuro"
					height={100}
				/>
			</div>
			<h1 className="text-center">Login</h1>
			<form
				className="form w-100"
				onSubmit={formik.handleSubmit}
				noValidate
				id="kt_login_signin_form"
			>
				<div className="fv-row mb-10">
					<label className="form-label fs-6 fw-bolder text-dark">CPF</label>
					<InputMask
						{...formik.getFieldProps("identifier")}
						mask="999.999.999-99"
						name="identifier"
						placeholder="000.000.000-00"
						className={clsx(
							"form-control form-control-lg",
							{
								"is-invalid": formik.errors.identifier,
							},
							{
								"is-valid":
									!formik.errors.identifier &&
									!errorsMessageLoginUser &&
									formik.touched.identifier,
							}
						)}
					/>
					{formik.touched.identifier && formik.errors.identifier && (
						<div className="fv-plugins-message-container text-danger">
							<span role="alert">{formik.errors.identifier}</span>
						</div>
					)}
				</div>

				<div className="fv-row mb-3">
					<div className="d-flex justify-content-between mt-n5">
						<div className="w-100 d-flex flex-stack mb-2">
							<label className="form-label fw-bolder text-dark fs-6 mb-0">
								Senha
							</label>
						</div>
					</div>
					<input
						placeholder="Senha"
						type="password"
						autoComplete="off"
						{...formik.getFieldProps("password")}
						className={clsx(
							"form-control form-control-lg",
							{
								"is-invalid": formik.touched.password && formik.errors.password,
							},
							{
								"is-valid": formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className="fv-plugins-message-container text-danger">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.password}</span>
							</div>
						</div>
					)}
					{errorsMessageLoginUser && (
						<div className="fv-plugins-message-container text-danger">
							<div className="fv-help-block">
								<span role="alert">CPF ou senha inválidos</span>
							</div>
						</div>
					)}
				</div>

				<div className="d-flex justify-content-end gap-3 fs-base fw-semibold mb-8">
					<Link to="/auth/esqueci-a-senha" className="link-primary">
						Esqueceu a senha ?
					</Link>
				</div>

				<div className="text-center">
					<button
						type="submit"
						id="kt_sign_in_submit"
						className="btn btn-lg btn-primary w-75 mb-5"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && <span className="indicator-label">Entrar</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: "block" }}>
								Por favor, aguarde...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>
				</div>

				<div className="text-center mt-5 fw-bold">
					<span className="text-muted">Não tem uma conta?</span>{" "}
					<Link to="/auth/criar-conta" className="opacity-75-hover text-primary me-1">
						Criar conta
					</Link>
				</div>
			</form>
			<div className={`d-flex flex-column justify-content-end align-items-center`}>
				<span className="text-white mb-1" id="product-title">
					Um produto:
				</span>
				<div>
					<img
						src={toAbsoluteUrl("/media/logos/logo-multintegrada.png")}
						height={40}
						alt="Multintegrada - Tecnologia"
					/>
				</div>
			</div>
			<div className="d-flex w-100 justify-content-end ">
				<label className="d-flex">versão 2.5.0</label>
			</div>
		</div>
	);
}
