import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Swal from "sweetalert2";
import {
	FaChild,
	FaHandHoldingWater,
	FaHandPaper,
	FaHeadSideCough,
	FaStethoscope,
} from "react-icons/fa";
import { MdElderly, MdOutlinePregnantWoman } from "react-icons/md";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import {
	getHomeVisitsResumeByCitizenCondition,
	getHomeVisitsDashboard,
	getHomeVisitsResume,
	CitizensConditionalResume,
	AmountVisits,
} from "@/services/esus/homeVisitService";
import { getTotalCitizens } from "@/services/esus/territorialCitizenService";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { Professional } from "@/@types/esus/Professional";
import { Option } from "@/@types/generics/Option";

import { CardsInformativesVisits } from "./CardsInformativesVisits";
import { VisitByAcsChart } from "./VisitByAcsChart";
import { GlobalInformativeVisits } from "./GlobalInformativeVisits";
import { LoadingScreen } from "@/components/LoadingScreen";
import { FilterAcsReport } from "./FilterAcsReport";
import { DonutChart } from "@/components/Charts/DonutChart";

type ProfessionalAcsDashboard = AmountVisits & {
	professional: Professional;
};

export type VisitsResume = {
	totalAcs: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherResidences: number;
};

type Props = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export function AcsVisitsControl({ uf, ibgeCode, cnes }: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const [totalCitizens, setTotalCitizens] = useState<number>();
	const [visitsResume, setVisitsResume] = useState({} as VisitsResume);
	const [selectedHealthUnit, setSelectedHealthUnit] = useState<Option<string>>(
		{} as Option<string>
	);

	const [selectedStartDate, setSelectedStartDate] = useState(
		dayjs().subtract(1, "month").format("YYYY-MM-DD")
	);

	const [selectedEndDate, setSelectedEndDate] = useState(dayjs().format("YYYY-MM-DD"));
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>(
		{} as Option<string>
	);
	const [selectedProfessional, setSelectedProfessional] = useState<Option<string>>(
		{} as Option<string>
	);
	const [professionalsAcsDashboard, setProfessionalsAcsDashboard] = useState<
		ProfessionalAcsDashboard[]
	>([]);

	const [citizensConditionsResume, setCitizensConditionsResume] =
		useState<CitizensConditionalResume>({} as CitizensConditionalResume);

	const cacheExpirationDate = getExpirationDateMidnight();

	async function handleProfessionalVisits() {
		const cacheKey = `[acsVisitsControl-professionalVisits][${uf}][${ibgeCode}][${
			selectedHealthTeam.value
		}][${selectedHealthUnit.value || cnes}][${
			selectedProfessional.value
		}][${selectedStartDate}][${selectedEndDate}][${3}]`;
		return fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getHomeVisitsDashboard({
				uf,
				ibgeCode,
				ine: selectedHealthTeam.value,
				cnes: selectedHealthUnit.value || cnes,
				professionalCns: selectedProfessional.value,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
				aggregator: 3,
			})
		);
	}

	async function handleVisitsResume() {
		const cacheKey = `[acsVisitsControl-visitsResume][${uf}][${ibgeCode}][${
			selectedHealthTeam.value
		}][${selectedHealthUnit.value || cnes}][${
			selectedProfessional.value
		}][${selectedStartDate}][${selectedEndDate}][${3}]`;

		return fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getHomeVisitsResume({
				uf,
				ibgeCode,
				ine: selectedHealthTeam.value,
				cnes: selectedHealthUnit.value || cnes,
				professionalCns: selectedProfessional.value,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
				aggregator: 3,
			})
		);
	}

	async function handleCitizensConditionalResume() {
		const cacheKey = `[acsVisitsControl-conditionalResume][${uf}][${ibgeCode}][${
			selectedHealthTeam.value
		}][${selectedHealthUnit.value || cnes}][${
			selectedProfessional.value
		}][${selectedStartDate}][${selectedEndDate}]`;

		return fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getHomeVisitsResumeByCitizenCondition({
				uf,
				ibgeCode,
				ine: selectedHealthTeam.value,
				cnes: selectedHealthUnit.value || cnes,
				professionalCns: selectedProfessional.value,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
			})
		);
	}

	async function handleTotalCitizens() {
		const cacheKey = `[acsVisitsControl-TotalCitizens][${uf}][${ibgeCode}][${
			selectedHealthTeam.value
		}][${selectedHealthUnit.value || cnes}]`;
		return fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getTotalCitizens({
				uf,
				ibgeCode,
				ine: selectedHealthTeam.value,
				cnes: selectedHealthUnit.value || cnes,
			})
		);
	}

	async function fetch() {
		setIsLoading(true);
		const [
			professionalsAcsDashboardDb,
			citizenConditionsResumeDb,
			visitsResumeDb,
			totalCitizensDb,
		] = await Promise.all([
			handleProfessionalVisits(),
			handleCitizensConditionalResume(),
			handleVisitsResume(),
			handleTotalCitizens(),
		]);

		setProfessionalsAcsDashboard(professionalsAcsDashboardDb);
		setCitizensConditionsResume(citizenConditionsResumeDb);
		setVisitsResume(visitsResumeDb);
		setTotalCitizens(totalCitizensDb);
		setIsLoading(false);
	}

	async function handleSearch() {
		if (
			dayjs(selectedEndDate).diff(dayjs(selectedStartDate), "years") > 1 ||
			dayjs().diff(dayjs(selectedStartDate), "years") > 2
		) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Data inicial ultrapassa o limite de consulta!",
				confirmButtonColor: "#3085d6",
			});
			return;
		}
		fetch();
	}

	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<FilterAcsReport
				handleSearch={handleSearch}
				setSelectedStartDate={setSelectedStartDate}
				setSelectedProfessional={setSelectedProfessional}
				setSelectedHealthUnit={setSelectedHealthUnit}
				setSelectedHealthTeam={setSelectedHealthTeam}
				setSelectedEndDate={setSelectedEndDate}
				selectedStartDate={selectedStartDate}
				selectedEndDate={selectedEndDate}
			/>
			<div>
				<LoadingScreen loading={isLoading} />
				<section>
					<GlobalInformativeVisits
						total={professionalsAcsDashboard
							.map(
								(professionalAcsDashboard) => professionalAcsDashboard?.totalVisit!
							)
							.reduce((acc, current) => acc + current, 0)}
						totalAcs={visitsResume.totalAcs}
						totalFamily={visitsResume.totalFamilies}
						totalOtherBuildings={visitsResume.totalOtherResidences}
						totalRegistraionFa={visitsResume.totalFA}
						totalRegistrationMc={visitsResume.totalMC}
						totalResidences={visitsResume.totalResidences}
					/>
				</section>
				<Row className="d-flex mx-1">
					<Col md={6} lg={6} xl={6} className="my-5 mb-2">
						<Card className="w-100 h-100">
							<Card.Title className="align-items-start flex-column w-100 pt-3 ps-3">
								<Card.Subtitle className="text-uppercase fw-bolder fs-7 mb-1 w-100">
									Proporção de cidadãos visitados X não visitados
								</Card.Subtitle>
							</Card.Title>
							<Card.Body className="d-flex w-100 p-0 flex-column align-items-center">
								<div className="d-flex align-items-center justify-content-center w-100 h-100 ">
									<DonutChart
										labels={["Com condição", "Outros motivos", "Não visitados"]}
										series={
											isLoading
												? [0]
												: [
														citizensConditionsResume?.totalProblemsYes ??
															0,
														citizensConditionsResume?.totalProblemsNo ??
															0,
														(totalCitizens || 0) -
															((citizensConditionsResume?.totalProblemsYes ||
																0) +
																(citizensConditionsResume?.totalProblemsNo ||
																	0)),
												  ]
										}
										colors={["#2898FF", "#055C8C", "#aaa"]}
										width={"450px"}
									/>
								</div>
							</Card.Body>
						</Card>
					</Col>

					<Col md={6} lg={6} xl={6} className="my-5 mb-2">
						<Card className="w-100 h-100">
							<Card.Subtitle className="pt-3 ps-3 fw-bolder fs-7 mb-1 w-100 text-uppercase">
								Supervisão de visitas por condição
							</Card.Subtitle>
							<Card.Body className="px-3 w-100 h-100">
								<Table
									className="table table-borderless my-0"
									style={{ border: "0px !important" }}
								>
									<thead>
										<tr>
											<th className="fs-8 fw-bolder">CONDIÇÃO</th>
											<th className="fs-8 fw-bolder text-end">
												CIDADÃOS VISITADOS
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<MdOutlinePregnantWoman className="fs-1 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Gestantes
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalPregnants?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<FaChild className="fs-2 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Crianças menores de 5 anos
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalChild?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<MdElderly className="fs-1 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Idosos apartir de 60 anos
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalOld?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<FaStethoscope className="fs-2 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Hipertensos
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalHypertension?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<FaHandHoldingWater className="fs-2 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Diabéticos
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalDiabetes?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<FaHandPaper className="fs-2 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Hanseniase
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalHansen?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
										<tr>
											<td className="px-2">
												<div className="d-flex align-items-center py-1">
													<FaHeadSideCough className="fs-2 text-muted me-3" />
													<label className="text-muted fw-bolder">
														Tuberculose
													</label>
												</div>
											</td>
											<td className="text-end px-2">
												<span className="text-muted fw-bolder fs-8">
													{citizensConditionsResume?.totalTuberculosis?.toLocaleString() ||
														0}
												</span>
											</td>
										</tr>
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<h1 className="bg-primary text-white fw-bolder mx-3 text-center py-1 mb-5">
					CONTROLE DE VISITAS
				</h1>
				<CardsInformativesVisits
					totalAbsent={professionalsAcsDashboard
						.map((professionalAcsDashboard) => professionalAcsDashboard.totalAbsent!)
						.reduce((acc, current) => acc + current, 0)}
					totalRealized={professionalsAcsDashboard
						.map((professionalAcsDashboard) => professionalAcsDashboard.totalRealized!)
						.reduce((acc, current) => acc + current, 0)}
					totalRejected={professionalsAcsDashboard
						.map((professionalAcsDashboard) => professionalAcsDashboard.totalRejected!)
						.reduce((acc, current) => acc + current, 0)}
					totalVisits={professionalsAcsDashboard
						.map((professionalAcsDashboard) => professionalAcsDashboard.totalVisit!)
						.reduce((acc, current) => acc + current, 0)}
				/>

				<div className="p-2">
					<VisitByAcsChart
						startDate={selectedStartDate}
						endDate={selectedEndDate}
						height={
							professionalsAcsDashboard.length < 10
								? 400
								: professionalsAcsDashboard.length * 30
						}
						title={{ text: "Visitas por Acs" }}
						categories={professionalsAcsDashboard.map(
							(professionalAcsDashboard) => professionalAcsDashboard.professional
						)}
						series={[
							{
								name: "Visitas Realizadas",
								data: professionalsAcsDashboard.map(
									(professionalAcsDashboard) =>
										professionalAcsDashboard.totalRealized!
								),
							},
							{
								name: "Visitas Ausentes",
								data: professionalsAcsDashboard.map(
									(professionalAcsDashboard) =>
										professionalAcsDashboard.totalAbsent!
								),
							},
							{
								name: "Visitas Recusadas",
								data: professionalsAcsDashboard.map(
									(professionalAcsDashboard) =>
										professionalAcsDashboard.totalRejected!
								),
							},
						]}
						colors={["#007bff", "#ffc107", "#dc3545"]}
					/>
				</div>
			</div>
		</div>
	);
}
