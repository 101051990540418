import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal, { ModalProps } from "react-bootstrap/Modal";

import { updateCityPopulationClassification } from "@/services/app/cityService";
import { Account } from "@/@types/app/Account";

type Props = ModalProps & {
	city: Account;
};

export function UpdateCityPopulationModal({ city, ...rest }: Props) {
	const [totalCitizens, setTotalCitizens] = useState("");
	async function handleUpdateCity() {
		return await updateCityPopulationClassification({
			ibgeCode: city.ibgeCode,
			population: Number(totalCitizens),
		});
	}

	return (
		<Modal scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title className="text-center">
					Atualizar estimativa populacional do municipio
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						handleUpdateCity();
					}}
				>
					<Form.Group className="d-flex gap-3 mb-4">
						<Form.Label>
							<strong>Municipio:</strong> {city.locale}
						</Form.Label>
						<Form.Label>
							<strong>IbgeCode:</strong> {city.ibgeCode}
						</Form.Label>
					</Form.Group>
					<Form.Group className="mb-4">
						<Form.Label>População estimada: </Form.Label>
						<Form.Control
							type="number"
							name="population"
							value={totalCitizens}
							onChange={(value) => setTotalCitizens(value.currentTarget.value)}
							required
						/>
					</Form.Group>
					<div className="text-center">
						<Button type="submit"> Salvar </Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
