import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export type ChartAnnotation = {
	value: number;
	lineColor: string;
	color: string;
	backgroundColor: string;
	text: string;
};

export type BarChartProps = {
	isLoading?: boolean;
	minHeight?: number;
	baseMultiplier?: number;
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
	apexOptions?: ApexOptions;
	isVertical?: boolean;
	colors?: string[];
	dataLabelsColors?: string[];
	annotations?: ChartAnnotation[];
	isTotalDatalabelEnable?: boolean;
	isStacked?: boolean;
	toolbar?: boolean;
	zoom?: boolean;
	paddingTop?: number;
	paddingleft?: number;
	paddingRight?: number;
	paddingBottom?: number;
	categoryTextWidth?: number;
	dataEvent?: (value: number) => void;
	xAxisEvent?: (value: number) => void;
};

export function BarChart({
	isLoading,
	minHeight,
	baseMultiplier,
	paddingBottom,
	paddingRight,
	paddingTop,
	paddingleft,
	isStacked,
	colors,
	dataLabelsColors,
	isTotalDatalabelEnable,
	categories,
	series,
	apexOptions,
	isVertical,
	categoryTextWidth,
	annotations,
	dataEvent,
	xAxisEvent,
}: BarChartProps) {
	const barChartOption: ApexOptions = apexOptions || {
		chart: {
			type: "bar",
			stacked: isStacked,
			toolbar: {
				show: true,
			},
			zoom: {
				enabled: true,
			},
			events: {
				dataPointSelection(event, chartContext, options) {
					dataEvent && dataEvent(options?.dataPointIndex);
				},
				dataPointMouseEnter: function (event) {
					event.target.style.cursor = "pointer";
				},
				xAxisLabelClick(event, chart, options?) {
					xAxisEvent && xAxisEvent(options?.labelIndex);
				},
			},
		},
		noData: {
			text: isLoading ? "Carregando..." : "Nenhum resultado encontrado!!!",
			align: "center",
			verticalAlign: "middle",
			style: {
				color: "#888",
			},
		},
		annotations: !isVertical
			? {
					xaxis: annotations?.map<XAxisAnnotations>((annotation) => {
						return {
							x: annotation.value,
							borderColor: annotation.lineColor,
							label: {
								offsetY: -10,
								orientation: "horizontal",
								text: annotation.text,
								borderColor: annotation.color,
								style: {
									fontWeight: "bolder",
									color: annotation.color,
									background: annotation.backgroundColor,
									fontSize: "12px",
								},
							},
						};
					}),
			  }
			: {
					yaxis: annotations?.map<YAxisAnnotations>((annotation) => {
						return {
							y: annotation.value,
							borderColor: annotation.lineColor,
							label: {
								offsetY: 0,
								orientation: "horizontal",
								text: annotation.text,
								borderColor: annotation.color,
								style: {
									fontWeight: "bolder",
									color: annotation.color,
									background: annotation.backgroundColor,
									fontSize: "12px",
								},
							},
						};
					}),
			  },

		grid: {
			padding: {
				top: paddingTop ?? 5,
				right: paddingRight ?? 5,
				bottom: paddingBottom ?? 5,
				left: paddingleft ?? 5,
			},
		},
		colors: colors && colors,
		plotOptions: {
			bar: {
				horizontal: !isVertical,
				borderRadius: 10,
				borderRadiusApplication: "end",
				borderRadiusWhenStacked: "last",
				barHeight: "80%",
				columnWidth: "80%",
				dataLabels: {
					total: {
						enabled: isTotalDatalabelEnable,
						style: {
							fontSize: "13px",
							fontWeight: 900,
						},
						formatter: (val: string) => val,
					},
				},
			},
		},
		dataLabels: {
			enabled: true,
			formatter: (val: number) => val.toLocaleString(),
			style: {
				colors: dataLabelsColors ?? ["#FFFFFF"],
				fontSize: "14px",
			},
			textAnchor: "middle",
			offsetX: 10,
			dropShadow: {
				enabled: true,
				top: 1,
				left: 1,
				opacity: 0.5,
				blur: 1,
				color: "#000000",
			},
		},
		yaxis: {
			labels: { show: true, align: "left", maxWidth: categoryTextWidth ?? 100 },
		},
		xaxis: {
			type: "category",
			categories: !isLoading ? categories : [],
			min: 0,
			max: undefined,
		},
		legend: {
			position: "top",
		},
		fill: {
			opacity: 1,
		},
		responsive: [
			{
				breakpoint: 1000,
				options: {
					plotOptions: {
						bar: {
							horizontal: !isVertical,
							barHeight: "80%",
							columnWidth: "80%",
						},
					},
					chart: {
						height: "100%",
						width: "100%",
					},
					legend: {
						show: true,
						horizontalAlign: "left",
					},
					yaxis: {
						show: true,
						labels: { align: "left", maxWidth: 100, style: { fontSize: "11px" } },
					},
					xaxis: {
						labels: {
							show: false,
						},
					},
				} as ApexOptions,
			},
		],
	};

	const calculateMinHeightBarChart = (itemsLength: number, multiplierBar?: number) => {
		const valueDefault = 40;
		const barHeight = multiplierBar ?? valueDefault;
		return `${Math.max(itemsLength * barHeight, 300)}px`;
	};

	return (
		<div
			style={{
				flexGrow: 1,
				width: "100%",
				minHeight: !isLoading
					? minHeight ?? calculateMinHeightBarChart(categories?.length, baseMultiplier)
					: 300,
				height: "100%",
			}}
		>
			<ReactApexChart
				options={barChartOption}
				series={!isLoading ? series : []}
				type="bar"
				height="100%"
				width="100%"
			/>
		</div>
	);
}
