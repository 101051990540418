import { get } from "../api";
import { Professional } from "@/@types/esus/Professional";
import { Paginated } from "@/@types/generics/paginated";
import { HomeVisit } from "@/@types/esus/HomeVisit";

export type AmountVisits = {
	totalVisit?: number;
	totalRealized?: number;
	totalRejected?: number;
	totalAbsent?: number;
};

type ProfessionalAcsDashboard = AmountVisits & {
	professional: Professional;
};

export type CitizensConditionalResume = {
	totalPregnants: number;
	totalTuberculosis: number;
	totalHansen: number;
	totalOld: number;
	totalDiabetes: number;
	totalHypertension: number;
	totalChild: number;
	totalProblemsYes: number;
	totalProblemsNo: number;
};

export type VisitsResume = {
	totalAcs: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherResidences: number;
};

type HomeVisitsParams = {
	uf: string;
	ibgeCode: string;
	ine: string;
	cnes: string;
	professionalCns: string;
	startDate: string;
	endDate: string;
	aggregator: number;
};

type HomeVisitsConditionalResumeParams = {
	uf: string;
	ibgeCode: string;
	ine: string;
	cnes: string;
	professionalCns: string;
	startDate: string;
	endDate: string;
};

type HomeVisitParamsPaginated = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	fullName: string;
	professionalCns: string;
	homeVisitConclusionTypeId?: number;
	startDate: string;
	endDate: string;
	pageNumber: number;
	pageSize: number;
};

export async function getHomeVisitsResume({ uf, ibgeCode, ...params }: HomeVisitsParams) {
	const response = await get<VisitsResume>(
		`/uf/${uf}/city/${ibgeCode}/homeVisit/getVisitResume`,
		params
	);
	return response.data || ({} as VisitsResume);
}

export async function getHomeVisitsDashboard({ uf, ibgeCode, ...params }: HomeVisitsParams) {
	const response = await get<ProfessionalAcsDashboard[]>(
		`/uf/${uf}/city/${ibgeCode}/homeVisit/getDashboard`,
		params
	);
	return response.data || [];
}

export async function getHomeVisitsResumeByCitizenCondition({
	uf,
	ibgeCode,
	...params
}: HomeVisitsConditionalResumeParams) {
	const response = await get<CitizensConditionalResume>(
		`/uf/${uf}/city/${ibgeCode}/homeVisit/getResumeByCitizens`,
		params
	);
	return response.data || ({} as CitizensConditionalResume);
}

export async function getHomeVisitsPaginated({
	uf,
	ibgeCode,
	...params
}: HomeVisitParamsPaginated) {
	const response = await get<Paginated<HomeVisit>>(
		`/uf/${uf}/city/${ibgeCode}/homeVisit/getPaginated`,
		params
	);
	return response.data || ({} as Paginated<HomeVisit>);
}
