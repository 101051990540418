import { ClassificationResourceEvaluated } from "@/@types/esus/ClassificationResourceEvaluated";
import { HealthTeamTypeEnum } from "./enums/HealthTeamTypeEnum";

export function getScoreDimensionRegister(result: number): ClassificationResourceEvaluated {
	if (result >= 8.5) {
		return { value: 3, classification: "Ótimo" };
	} else if (result >= 6.5 && result < 8.5) {
		return { value: 2.25, classification: "Bom" };
	} else if (result >= 4.5 && result < 6.5) {
		return { value: 1.5, classification: "Suficiente" };
	} else {
		return { value: 0.75, classification: "Regular" };
	}
}

export function getScoreDimensionAccompaniment(result: number): ClassificationResourceEvaluated {
	if (result >= 8.5) {
		return { value: 7, classification: "Ótimo" };
	} else if (result >= 6.5 && result < 8.5) {
		return { value: 5.25, classification: "Bom" };
	} else if (result >= 4.5 && result < 6.5) {
		return { value: 3.5, classification: "Suficiente" };
	} else {
		return { value: 1.75, classification: "Regular" };
	}
}

export function getResourceEvaluatedByResult(
	result: number,
	hasPenalty: boolean,
	healthTeamType: HealthTeamTypeEnum
) {
	if (result >= 8.5) {
		if (healthTeamType === HealthTeamTypeEnum.ESF) {
			if (hasPenalty) {
				return { value: 6000, classification: "Bom" };
			} else {
				return { value: 8000, classification: "Ótimo" };
			}
		} else if (healthTeamType === HealthTeamTypeEnum.EAP) {
			if (hasPenalty) {
				return { value: 2250, classification: "Bom" };
			} else {
				return { value: 3000, classification: "Ótimo" };
			}
		}
	} else if (result >= 7 && result < 8.5) {
		if (healthTeamType === HealthTeamTypeEnum.ESF) {
			return { value: 6000, classification: "Bom" };
		} else if (healthTeamType === HealthTeamTypeEnum.EAP) {
			return { value: 2250, classification: "Bom" };
		}
	} else if (result >= 5 && result < 7) {
		if (healthTeamType === HealthTeamTypeEnum.ESF) {
			return { value: 4000, classification: "Suficiente" };
		} else if (healthTeamType === HealthTeamTypeEnum.EAP) {
			return { value: 1500, classification: "Suficiente" };
		}
	} else if (result < 5) {
		if (healthTeamType === HealthTeamTypeEnum.ESF) {
			return { value: 2000, classification: "Regular" };
		} else if (healthTeamType === HealthTeamTypeEnum.EAP) {
			return { value: 750, classification: "Regular" };
		}
	}
}

export function calculateRegistrationDimensionResult(
	totalFciFinanced: number,
	totalRegisterFinancedCompleted: number,
	parameter: number
) {
	const registrationDimensionResult =
		((totalFciFinanced * 0.75 + totalRegisterFinancedCompleted * 1.5) / parameter) * 10;
	return registrationDimensionResult;
}

export function calculateAccompanimentDimensionResult(
	vulnerableATotal: number,
	vulnerableBTotal: number,
	vulnerableCTotal: number,
	vulnerableDTotal: number,
	parameter: number
) {
	const accompanimentDimensionResult =
		((vulnerableATotal * 1 +
			vulnerableBTotal * 1.2 +
			vulnerableCTotal * 1.3 +
			vulnerableDTotal * 2.5) /
			parameter) *
		10;

	return accompanimentDimensionResult;
}
