import { Period } from "@/@types/generics/Period";
import { get } from "../api";
import { ResumeGrouped } from "./attendanceService";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";
import { THealthUnit } from "@/@types/esus/THealthUnit";
import { THealthTeam } from "@/@types/esus/THealthTeam";
import { Paginated } from "@/@types/generics/paginated";
import { CitizenExtra } from "@/@types/esus/CitizenExtra";
import { Professional } from "@/@types/esus/Professional";
import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";
import { Cbo } from "@/@types/esus/Cbo";
import { PaginationFilter } from "@/@types/generics/genericals";

export type TScheduleDashboard = {
	schedulesAggregatedByPeriodAndScheduleStatus?: ResumeGrouped<
		Period,
		ResumeGrouped<TScheduleStatus, object>
	>[];
	schedulesAggregatedByHealthUnitAndStatus?: ResumeGrouped<
		THealthUnit,
		ResumeGrouped<TScheduleStatus, object>
	>[];
	schedulesAggregatedByHealthTeamAndStatus?: ResumeGrouped<
		THealthTeam,
		ResumeGrouped<TScheduleStatus, object>
	>[];
	schedulesAggregatedByProfessionalAndStatus?: ResumeGrouped<
		THealthTeam,
		ResumeGrouped<TScheduleStatus, object>
	>[];
	schedulesAggregatedByCategories?: ResumeGrouped<
		string,
		ResumeGrouped<TScheduleStatus, object>
	>[];
};

export type TScheduleDetails = {
	category?: keyof typeof ProfessionalCategoryEnum;
	status?: TScheduleStatus;
	annotation?: string;
	scheduleDate?: string;
	professional?: Professional;
	citizenExtra?: CitizenExtra;
	cbo?: Cbo;
};

type ScheduleDashboardParams = {
	startDate: string;
	endDate: string;
	uf: string;
	ibgeCode: string;
	fullName?: string;
	cnes?: string;
	ine?: string;
	cns?: string;
	professionalCategory?: string;
	scheduleStatusId?: number;
};

type ScheduleStatusParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export async function getScheduleStatus({ uf, ibgeCode, ...params }: ScheduleStatusParams) {
	const { data } = await get<TScheduleStatus[]>(
		`/uf/${uf}/city/${ibgeCode}/TSchedule/scheduleStatus`,
		params
	);
	return data || [];
}

export async function getDailyScheduleDashboard({
	uf,
	ibgeCode,
	...params
}: ScheduleDashboardParams) {
	const { data } = await get<TScheduleDashboard>(
		`/uf/${uf}/city/${ibgeCode}/Tschedule/DailyScheduleDashboard`,
		params
	);
	return data || ({} as TScheduleDashboard);
}

export async function getDailyScheduleDetails({
	uf,
	ibgeCode,
	...params
}: ScheduleDashboardParams & PaginationFilter) {
	const { data } = await get<Paginated<TScheduleDetails>>(
		`/uf/${uf}/city/${ibgeCode}/TSchedule/DailyScheduleDetails`,
		params
	);
	return data || ({} as Paginated<TScheduleDetails>);
}
