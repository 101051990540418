import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsClipboardData } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import { MdAttachMoney, MdOutlineMoneyOff } from "react-icons/md";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
	GetTerritorialCitizenRegistrationResume,
	TerritorialCitizenRegistrationResume,
} from "@/services/esus/territorialCitizenService";
import { TerritorialCitizenRegisterStatusEnum } from "@/utils/enums/TerritorialCitizenRegisterStatus";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { HealthTeamTypeEnum } from "@/utils/enums/HealthTeamTypeEnum";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { useLayout } from "@/../../src/_metronic/layout/core";
import { Option } from "@/@types/generics/Option";
import { GetCityPopulationClassification } from "@/services/app/cityService";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import {
	CityPopulationClassification,
	citiesPopulationsClassifications,
} from "@/utils/cityPopulationClasssification";

import { RegistrationDimensionCards } from "./RegistrationDimensionCards";
import { DonutChart } from "@/components/Charts/DonutChart";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { TeamPerformanceCharts } from "../TeamPerformanceCharts";
import { ChartAnnotation } from "@/components/Charts/BarChart";
import { QuartersSelect } from "@/components/QuartersSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { LoadingScreen } from "@/components/LoadingScreen";
import { Skeleton } from "@/components/Skeleton";

type SeriesData = {
	punishment: string;
	classification: string;
	name: string;
	data: number[];
};

export function RegistrationDimension() {
	const { setTitle } = useLayout();
	const { ibgeCode, uf, cnes, currentAccount } = useCurrentAccount();
	const { healthTeams } = useHealthTeams();
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>();
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [isLoadingCityClassificationHistory, setIsLoadingCityClassificationHistory] =
		useState(false);
	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<Option<string>>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});
	const [territorialCitizensRegistersResume, setTerritorialCitizensRegistersResume] =
		useState<TerritorialCitizenRegistrationResume>();

	const [keyMenuPerformanceRegistrationHealthTeam, setKeyMenuPerformanceRegistrationHealthTeam] =
		useState<HealthTeamTypeEnum | null>(HealthTeamTypeEnum.ESF);

	const [cityPopulationClassification, setCityPopulationClassification] = useState<
		CityPopulationClassification | undefined
	>();

	const healthTeamTypeParams = [
		{
			ms: cityPopulationClassification?.parameter.esf.code,
			min: cityPopulationClassification?.parameter.esf.min,
			max: cityPopulationClassification?.parameter.esf.max,
		},
		{
			ms: cityPopulationClassification?.parameter.eap20.code,
			min: cityPopulationClassification?.parameter.eap20.min,
			max: cityPopulationClassification?.parameter.eap20.max,
		},
	];

	const healthTeamTypeParamSelected =
		healthTeamTypeParams.find(
			(healthTeamTypeParam) =>
				healthTeamTypeParam.ms === keyMenuPerformanceRegistrationHealthTeam
		) || healthTeamTypeParams[0];

	const chartAnnotations: ChartAnnotation[] = [
		{
			value: healthTeamTypeParamSelected.min || 0,
			lineColor: "#ffc700",
			color: "#ffc700",
			backgroundColor: "#FFFFFF",
			text: `Parâmetro: ${healthTeamTypeParamSelected.min?.toLocaleString()}`,
		},
		{
			value: healthTeamTypeParamSelected.max || 0,
			lineColor: "#FF4E79",
			color: "#FF4E79",
			backgroundColor: "#FFFFFF",
			text: `Teto: ${healthTeamTypeParamSelected.max?.toLocaleString()}`,
		},
	];

	const fciFinancedSerie =
		getResumeBySelectedHealthTeamType().flatMap(
			(healthTeam) =>
				healthTeam.values
					?.filter(
						(item) =>
							item.key ===
								TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED ||
							item.key ===
								TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED
					)
					.map((statusGroup) => statusGroup.total || 0)
					.reduce((acc, curr) => acc + curr, 0) || 0
		) || [];

	const registerCompletedFinancedSerie =
		getResumeBySelectedHealthTeamType().flatMap(
			(healthTeam) =>
				healthTeam.values?.find(
					(item) => item.key === TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED
				)?.total || 0
		) || [];

	const registersNotFinancedSerie =
		getResumeBySelectedHealthTeamType().flatMap(
			(healthTeam) =>
				healthTeam.values
					?.filter(
						(item) =>
							item.key !==
								TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED &&
							item.key !==
								TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED &&
							item.key !==
								TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED
					)
					.map((statusGroup) => statusGroup.total || 0)
					.reduce((acc, curr) => acc + curr, 0) || 0
		) || [];

	const seriesChart = [
		{
			data: fciFinancedSerie,
			classification: "",
			punishment: "",
			name: "FCI",
		},
		{
			data: registerCompletedFinancedSerie,
			classification: "",
			punishment: "",
			name: "FCI+FCDT",
		},
		{
			data: registersNotFinancedSerie,
			classification: "",
			punishment: "",
			name: "Não financiados",
		},
	] as SeriesData[];

	const categoriesChart = getResumeBySelectedHealthTeamType().map(
		(healthTeam) => healthTeam.key.name
	);

	const totalUpdatedFcdtWithoutFci =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) => item.key === TerritorialCitizenRegisterStatusEnum.FCDT_UPDATED_WITHOUT_FCI
		)?.total || 0;
	const totalOutdatedFcdtWithoutFci =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) => item.key === TerritorialCitizenRegisterStatusEnum.FCDT_OUTDATED_WITHOUT_FCI
		)?.total || 0;
	const totalFciOutdatedAndFcdtNotRegistered =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) =>
				item.key ===
				TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_NOT_REGISTERED
		)?.total || 0;
	const totalUpdatedFciAndOutdatedFcdt =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) =>
				item.key === TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED
		)?.total || 0;
	const totalOutdatedFciAndUpdatedFcdt =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) =>
				item.key === TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_UPDATED
		)?.total || 0;
	const totalUpdatedFciAndFcdt =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) => item.key === TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED
		)?.total || 0;
	const totalOutdatedFcitAndFcdt =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) => item.key === TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_OUTDATED
		)?.total || 0;

	const totalWithoutFciAndFcdt =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) => item.key === TerritorialCitizenRegisterStatusEnum.WITHOUT_FCI_AND_FCDT
		)?.total || 0;

	const totalFciUpdatedAndFcdtNotRegistered =
		territorialCitizensRegistersResume?.resumeByRegisterStatus?.find(
			(item) =>
				item.key ===
				TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED
		)?.total || 0;

	const tableStatusData = [
		{
			name: "FCI atualizado e FCDT atualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED,
			value: totalUpdatedFciAndFcdt,
			isFciFinanced: true,
			isFcdtFinanced: true,
		},
		{
			name: "FCI atualizado e FCDT desatualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED,
			value: totalUpdatedFciAndOutdatedFcdt,
			isFciFinanced: true,
			isFcdtFinanced: false,
		},
		{
			name: "FCI atualizado e FCDT sem cadastro",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED,
			value: totalFciUpdatedAndFcdtNotRegistered,
			isFciFinanced: true,
			isFcdtFinanced: false,
		},
		{
			name: "FCI desatualizado e FCDT atualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_UPDATED,
			value: totalOutdatedFciAndUpdatedFcdt,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
		{
			name: "FCI desatualizado e FCDT desatualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_OUTDATED,
			value: totalOutdatedFcitAndFcdt,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
		{
			name: "FCI desatualizado e FCDT sem cadastro",
			identifier: TerritorialCitizenRegisterStatusEnum.FCI_OUTDATED_AND_FCDT_NOT_REGISTERED,
			value: totalFciOutdatedAndFcdtNotRegistered,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
		{
			name: "FCI sem cadastro e FCDT atualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCDT_UPDATED_WITHOUT_FCI,
			value: totalUpdatedFcdtWithoutFci,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
		{
			name: "FCI sem cadastro e FCDT desatualizado",
			identifier: TerritorialCitizenRegisterStatusEnum.FCDT_OUTDATED_WITHOUT_FCI,
			value: totalOutdatedFcdtWithoutFci,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
		{
			name: "FCI e FCDT sem cadastro ( cadastro rápido )",
			identifier: TerritorialCitizenRegisterStatusEnum.WITHOUT_FCI_AND_FCDT,
			value: totalWithoutFciAndFcdt,
			isFciFinanced: false,
			isFcdtFinanced: false,
		},
	];

	const citizensTotal = tableStatusData
		.map((item) => item.value)
		.reduce((acc, curr) => acc + curr, 0);

	const fciAndFcdtFinancedTotal = tableStatusData
		.filter(
			(item) => item.identifier === TerritorialCitizenRegisterStatusEnum.FCI_AND_FCDT_UPDATED
		)
		.reduce((acc, curr) => acc + curr.value, 0);

	const fciFinancedTotal = tableStatusData
		.filter(
			(item) =>
				item.identifier ===
					TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_OUTDATED ||
				item.identifier ===
					TerritorialCitizenRegisterStatusEnum.FCI_UPDATED_AND_FCDT_NOT_REGISTERED
		)
		.reduce((acc, curr) => acc + curr.value, 0);

	const fciNotRegisteredTotal = tableStatusData
		.filter(
			(item) =>
				item.identifier ===
					TerritorialCitizenRegisterStatusEnum.FCDT_OUTDATED_WITHOUT_FCI ||
				item.identifier === TerritorialCitizenRegisterStatusEnum.FCDT_UPDATED_WITHOUT_FCI
		)
		.reduce((acc, curr) => acc + curr.value, 0);

	const fastRegistrationTotal = tableStatusData
		.filter(
			(item) => item.identifier === TerritorialCitizenRegisterStatusEnum.WITHOUT_FCI_AND_FCDT
		)
		.reduce((acc, curr) => acc + curr.value, 0);

	function getResumeBySelectedHealthTeamType() {
		switch (keyMenuPerformanceRegistrationHealthTeam) {
			case HealthTeamTypeEnum.ESF:
				return (
					territorialCitizensRegistersResume?.territorialCitizenRegisterByPeriodAndGroupedByhealthTeamESFResume ||
					[]
				);
			case HealthTeamTypeEnum.EAP:
				return (
					territorialCitizensRegistersResume?.territorialCitizenRegisterByPeriodAndGroupedByhealthTeamEAPResume ||
					[]
				);
			default:
				return [];
		}
	}

	async function handleTerritorialCitizenResume() {
		return await GetTerritorialCitizenRegistrationResume({
			ibgeCode: ibgeCode,
			uf: uf,
			quarter: selectedQuarter.value,
			ine: selectedHealthTeam?.value,
			cnes: cnes,
		});
	}

	async function fetch() {
		const cacheKey = `[registration-dimension-dashboard][${uf}][${ibgeCode}][${cnes}][${selectedQuarter.value}][${selectedHealthTeam?.value}]`;
		setIsLoadingResume(true);

		setTerritorialCitizensRegistersResume(
			await fetchWithCache(
				cacheKey,
				getExpirationDateMidnight(),
				handleTerritorialCitizenResume
			)
		);
		setIsLoadingResume(false);
	}

	async function handleCityPopulationClassification() {
		const cityPopulactionClassification = await GetCityPopulationClassification({
			cityId: currentAccount.id,
			quarter: selectedQuarter.value,
		});
		return citiesPopulationsClassifications.find(
			(city) =>
				city.cityPopulationClassification ===
				cityPopulactionClassification.cityPopulationClassificationId
		);
	}

	async function fetchSizeHistory() {
		setIsLoadingCityClassificationHistory(true);
		setCityPopulationClassification(await handleCityPopulationClassification());
		setIsLoadingCityClassificationHistory(false);
	}

	useEffect(() => {
		fetchSizeHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedQuarter]);

	useEffect(() => {
		fetch();
		setTitle("Dashboard Geral de cadastros");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<LoadingScreen loading={isLoadingResume} />
			<Row className="bg-white d-flex justify-content-between mx-3 p-2 gap-2">
				<Col sm={12} md={3} lg={3} xl={3}>
					<QuartersSelect
						value={selectedQuarter}
						onChange={(newValue) => setSelectedQuarter(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={12} md={4} lg={4} xl={4}>
					<HealthTeamsSelect
						healthTeams={healthTeams}
						value={selectedHealthTeam}
						onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
					/>
				</Col>
				<Col sm={2} md={2} lg={2} xl={2} className="d-flex align-items-end">
					<Button
						onClick={() => {
							fetch();
						}}
					>
						Consultar
					</Button>
				</Col>
			</Row>
			<Row className="m-3">
				<Card>
					<Card.Header className="d-flex justify-content-between ps-2">
						<Card.Title className="text-primary rounded gap-2">
							<label className="bg-primary text-secondary py-2 px-4 rounded fs-2 text-center">
								<BsClipboardData />
							</label>
							Dimensão cadastro
						</Card.Title>
						{!isLoadingCityClassificationHistory && cityPopulationClassification && (
							<Card.Title>
								<strong>Porte do municipio:</strong>
								<div className="ms-2">
									{`${cityPopulationClassification?.cityPopulationClassification} (${cityPopulationClassification?.description})`}
								</div>
							</Card.Title>
						)}
					</Card.Header>
				</Card>
			</Row>
			<RegistrationDimensionCards
				totalCitizenIdentified={citizensTotal}
				totalFciAndFcdtFinanced={fciAndFcdtFinancedTotal}
				totalFciFinanced={fciFinancedTotal}
				totalFciNotFinanced={
					citizensTotal -
					fciAndFcdtFinancedTotal -
					fciFinancedTotal -
					fastRegistrationTotal -
					fciNotRegisteredTotal
				}
				totalFciNotRegistered={fciNotRegisteredTotal}
				totalFastRegistrations={fastRegistrationTotal}
			/>

			<Row className="m-3">
				<Col sm={12} md={12} lg={4} xl={4} className="p-1">
					<Card className="h-100">
						<Card.Header className="p-2">
							<Card.Title className="gap-2">
								Cidadãos por tipo de cadastro
								<CardOverlayTrigger
									header="Proporção de cidadãos por tipo de cadastro:"
									message="Consideram-se cadastros atualizados e desatualizados por tipo de cadastro."
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center p-0">
							<DonutChart
								labels={["FCI+FCDT", "Somente FCI", "Somente FCDT"]}
								colors={["#008FFB", "#FFD52F", "#F1416C"]}
								series={[
									totalOutdatedFciAndUpdatedFcdt +
										totalOutdatedFcitAndFcdt +
										totalUpdatedFciAndOutdatedFcdt +
										totalUpdatedFciAndFcdt,
									totalFciOutdatedAndFcdtNotRegistered +
										totalFciUpdatedAndFcdtNotRegistered,
									totalOutdatedFcdtWithoutFci + totalUpdatedFcdtWithoutFci,
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={4} xl={4} className="p-1">
					<Card className="h-100">
						<Card.Header className="p-2">
							<Card.Title className="gap-2">
								Financiamento por FCI{" "}
								<CardOverlayTrigger
									header="Financiamento por FCI"
									message="Consideram-se apenas FCI atualizadas(finaciadas) e desatualizadas(não financiadas)."
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center p-0">
							<DonutChart
								labels={["Financiados", "Não financiados", "Sem FCI"]}
								colors={["#008FFB", "#F1416C", "#A3A5A5"]}
								series={[
									totalUpdatedFciAndFcdt +
										totalUpdatedFciAndOutdatedFcdt +
										totalFciUpdatedAndFcdtNotRegistered,
									totalOutdatedFcitAndFcdt +
										totalOutdatedFciAndUpdatedFcdt +
										totalFciOutdatedAndFcdtNotRegistered,
									totalUpdatedFcdtWithoutFci + totalOutdatedFcdtWithoutFci,
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={4} xl={4} className="p-1">
					<Card className="h-100">
						<Card.Header className="p-2">
							<Card.Title className="gap-2">
								Financiamento por FCDT
								<CardOverlayTrigger
									header="Financiamento por FCDT"
									message="Proporção de cidadãos com FCDT (atualizados e demais tipos não financiados)"
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center p-0">
							<DonutChart
								colors={["#50cd89", "#FF95AF", "#F1416C", "#A3A5A5"]}
								series={[
									totalUpdatedFciAndFcdt,
									totalOutdatedFciAndUpdatedFcdt +
										totalUpdatedFcdtWithoutFci +
										totalOutdatedFcitAndFcdt +
										totalUpdatedFciAndOutdatedFcdt +
										totalOutdatedFcdtWithoutFci,
									totalFciOutdatedAndFcdtNotRegistered +
										totalFciUpdatedAndFcdtNotRegistered,
								]}
								labels={["Financiados", "Não financiados", "Sem FCDT"]}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="m-3">
				<Col sm={12} md={12} lg={12} xl={12} className="p-1">
					<Card className="h-100">
						<Card.Header className="p-2">
							<Card.Title className="gap-2">
								Detalhamento por tipo de Status
								<CardOverlayTrigger
									header="Detalhamento por tipo de status"
									message="A situação de status individual do cidadão identificado"
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-2 w-100 h-100">
							{!isLoadingResume ? (
								<Table>
									<thead>
										<tr>
											<th className="fw-bolder text-center text-muted">
												Status
											</th>
											<th className="fw-bolder text-center pe-2 bg-primary opacity-75 text-white">
												FCI Financiado
											</th>
											<th className="fw-bolder text-center pe-2 bg-primary opacity-75 text-white">
												FCDT Financiado
											</th>
											<th className="fw-bolder text-center bg-info opacity-75 text-white">
												Total de cidadãos
											</th>
										</tr>
									</thead>
									<tbody>
										{tableStatusData.map((status, index) => (
											<tr key={index}>
												<td className="ps-2 text-muted fs-8">
													{status.name}
												</td>
												<td className="text-center text-info">
													{status.isFciFinanced ? (
														<MdAttachMoney
															className="text-success fw-bolder"
															size={20}
														/>
													) : (
														<MdOutlineMoneyOff
															className="text-secondary fw-bolder"
															size={20}
														/>
													)}
												</td>
												<td className="text-center text-info">
													{status.isFcdtFinanced ? (
														<MdAttachMoney
															className="text-success fw-bolder"
															size={20}
														/>
													) : (
														<MdOutlineMoneyOff
															className="text-secondary fw-bolder"
															size={20}
														/>
													)}
												</td>
												<td className="text-center text-info">
													{status.value?.toLocaleString()}
												</td>
											</tr>
										))}
										<tr>
											<td colSpan={3} className="text-start">
												Total
											</td>
											<td className="text-center">
												{citizensTotal.toLocaleString()}
											</td>
										</tr>
									</tbody>
								</Table>
							) : (
								<div className="d-flex flex-column w-100 text-center ">
									<Skeleton height={250} width="95%" />
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="m-3">
				<TeamPerformanceCharts
					title={`Desempenho`}
					keyMenu={String(keyMenuPerformanceRegistrationHealthTeam)}
					setKeyMenu={(value) => {
						setKeyMenuPerformanceRegistrationHealthTeam(Number(value));
					}}
					data={{
						series: seriesChart,
						categories: categoriesChart,
						annotations: chartAnnotations,
					}}
					isLoading={isLoadingResume}
					chartColors={["#009EF7", "#00E296", "#a3a5a5"]}
				/>
			</Row>
		</div>
	);
}
