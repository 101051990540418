import { useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Form from "react-bootstrap/Form";
import Popover from "react-bootstrap/Popover";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { Paginated } from "@/@types/generics/paginated";
import { PregnantPatient } from "@/services/esus/indicatorService";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { TableSkeletons } from "@/components/TableSkeletons";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator1 } from "../ModalIndicator1";

import "./styles.scss";

type Props = {
	pregnantPatient: Paginated<PregnantPatient>;
	isLoading: boolean;
};

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	sinc: Column;
	name: Column;
	birthdate: Column;
	identifier: Column;
	healthTeam: Column;
	mc: Column;
	dum: Column;
	firstAttendance: Column;
	lastAttendance: Column;
	attendancesTotal: Column;
	gestationalAge1St: Column;
	dpp: Column;
};

export function TableIndicator1({ isLoading, pregnantPatient }: Props) {
	const [patientSelected, setPatientSelected] = useState<PregnantPatient>({} as PregnantPatient);
	const [showModalAttendancePregnantWomen, setShowModalAttendancePregnantWomen] = useState(false);
	const handleShowModalAttendancePregnantWomen = () => {
		setShowModalAttendancePregnantWomen(true);
	};
	const handleCloseModalAttendancePregnantWomen = () => {
		setShowModalAttendancePregnantWomen(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		sinc: { title: "Sinc", visible: false },
		name: { title: "Nome", visible: true },
		birthdate: { title: "DN", visible: false },
		identifier: { title: "CPF/CNS", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		mc: { title: "MC", visible: false },
		dum: { title: "DUM", visible: true },
		firstAttendance: { title: "1º Atendimento", visible: true },
		lastAttendance: { title: "Último Atendimento", visible: false},
		attendancesTotal: { title: "Nº de Consultas", visible: false },
		gestationalAge1St: { title: "IG 1º Atendimento", visible: true },
		dpp: { title: "DPP", visible: true },
	});

	const handleColumnToggle = (column: keyof ColumnsTable) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[column]: {
				...prevColumns[column],
				visible: !prevColumns[column].visible,
			},
		}));
	};

	return (
		<div>
			<ModalIndicator1
				show={showModalAttendancePregnantWomen}
				onHide={handleCloseModalAttendancePregnantWomen}
				patientSelected={patientSelected}
			/>
			<Table
				id="indicator-1-table"
				responsive
				className="table-striped-custom table-rounded border gs-3"
			>
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						{columns.sinc.visible && (
							<th>
								<CitizenSyncLabel />
							</th>
						)}

						{columns.name.visible && <th className="white-space-nowrap">CIDADÃO</th>}
						{columns.birthdate.visible && <th className="white-space-nowrap">DN</th>}
						{columns.identifier.visible && (
							<th className="white-space-nowrap">CPF/CNS</th>
						)}

						{columns.healthTeam.visible && (
							<th className="white-space-nowrap">EQUIPE</th>
						)}
						{columns.mc.visible && (
							<th>
								<abbr title="Micro Área">MC</abbr>
							</th>
						)}
						{columns.dum.visible && (
							<th className="white-space-nowrap">
								<abbr title="Data da última menstruação">DUM</abbr>
							</th>
						)}
						{columns.firstAttendance.visible && (
							<th className="white-space-nowrap">
								<abbr title="Data do primeiro atendimento">1º ATENDIMENTO</abbr>
							</th>
						)}
						{columns.lastAttendance.visible && (
							<th className="white-space-nowrap">
								<abbr title="Data do último atendimento">ÚLTIMO ATENDIMENTO</abbr>
							</th>
						)}
						{columns.attendancesTotal.visible && (
							<th className="white-space-nowrap">Nº DE CONSULTAS</th>
						)}
						{columns.gestationalAge1St.visible && (
							<th className="white-space-nowrap">
								<abbr title="Idade gestacional na primeira consulta (IG)">
									IG 1º ATENDIMENTO
								</abbr>
							</th>
						)}
						{columns.dpp.visible && (
							<th className="white-space-nowrap">
								<abbr title="Data provável do parto (DPP)">DPP</abbr>
							</th>
						)}

						<th className="d-flex align-items-baseline justify-content-between text-nowrap pb-0 no-print no-excel">
							<label>AÇÕES</label>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && pregnantPatient?.data ? (
						pregnantPatient?.data?.map((patient, index) => {
							const numberOfAttendances = patient.attendances?.length;
							return (
								<tr key={`patient-pregnant-${index}`}>
									{columns.sinc.visible && (
										<td title="Sincronização">
											<PremilinaryReportStatusIcon
												status={patient.preliminaryReportStatus}
											/>
										</td>
									)}

									{columns.name.visible && (
										<td className="text-nowrap" title="Nome completo">
											{patient?.fullName || "N/A"}
										</td>
									)}
									{columns.birthdate.visible && (
										<td className="text-nowrap text-center">
											{patient.birthdate
												? dayjs(patient?.birthdate).format("DD/MM/YYYY")
												: "N/A"}
										</td>
									)}
									{columns.identifier.visible && (
										<td className="text-nowrap text-center" title="CPF/CNS">
											{identifierOrNationalHealthCard(patient) || "N/A"}
										</td>
									)}
									{columns.healthTeam.visible && (
										<td className="text-nowrap text-center" title="Equipe">
											{patient.healthTeam?.name || "N/A"}
										</td>
									)}
									{columns.mc.visible && (
										<td
											className="text-nowrap text-center text-center"
											title="Micro Área"
										>
											{patient.area || "N/A"}
										</td>
									)}
									{columns.dum.visible && (
										<td
											className="text-nowrap text-center"
											title="Data da última menstruação (DUM)"
										>
											{displayFirstDumDate(patient.firstDumDate) || "N/A"}
										</td>
									)}
									{columns.firstAttendance.visible && (
										<td
											title="Data do primeiro atendimento"
											className="text-nowrap text-center"
										>
											{patient.firstAttendanceDate
												? dayjs(patient.firstAttendanceDate).format(
														"DD/MM/YYYY"
												  )
												: "N/A"}
										</td>
									)}
									{columns.lastAttendance.visible && (
										<td
											title="Data do último atendimento"
											className="text-nowrap text-center"
										>
											{patient.lastAttendanceDate
												? dayjs(patient.lastAttendanceDate).format(
														"DD/MM/YYYY"
												  )
												: "N/A"}
										</td>
									)}

									{columns.attendancesTotal.visible && (
										<td
											className="text-nowrap text-center"
											title="Número de consultas"
										>
											{displaySingularOrPlural(
												numberOfAttendances,
												"consulta"
											) || "N/A"}
										</td>
									)}
									{columns.gestationalAge1St.visible && (
										<td
											className="text-nowrap text-center"
											title="Idade gestacional na primeira consulta (IG)"
										>
											{patient.gestationalAge1St
												? displaySingularOrPlural(
														patient.gestationalAge1St,
														"semana"
												  )
												: "N/A"}
										</td>
									)}
									{columns.dpp.visible && (
										<td
											className="text-nowrap text-center"
											title="Data provável do parto (DPP)"
										>
											{dayjs(patient.dppDate).format("DD/MM/YYYY") || "N/A"}
										</td>
									)}

									<td className="text-center no-print">
										<ViewCitizenButton
											onClick={() => {
												setPatientSelected(patient);
												handleShowModalAttendancePregnantWomen();
											}}
										/>
									</td>
								</tr>
							);
						})
					) : (
						<TableSkeletons
							numberOfCells={
								Object.entries(columns).filter((a) => a[1]?.visible === true)
									.length + 1
							}
							height={20}
							numberOfRows={10}
						/>
					)}
				</tbody>
			</Table>
		</div>
	);
}
