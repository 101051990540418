import Card from "react-bootstrap/Card";

import { ResumeGrouped } from "@/services/esus/attendanceService";
import { totalFilteredByStatus } from "@/pages/esus/Scheduling";
import { THealthUnit } from "@/@types/esus/THealthUnit";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";
import { Option } from "@/@types/generics/Option";

import { BarChart } from "@/components/Charts/BarChart";

type Props = {
	setSelectedHealthUnit?: (healthUnit: THealthUnit) => void;
	schedulesAggregatedByHealtUnitAndStatus?: ResumeGrouped<
		THealthUnit,
		ResumeGrouped<TScheduleStatus, object>
	>[];
};

export function SchedulingByHealthUnit({
	schedulesAggregatedByHealtUnitAndStatus,
	setSelectedHealthUnit,
}: Props) {
	const formatDataForChart = (
		data?: ResumeGrouped<THealthUnit, ResumeGrouped<TScheduleStatus, object>>[]
	) => {
		const categories =
			data?.map<Option<THealthUnit>>((item) => {
				return { value: item.key, label: item.key?.name ?? "NÃO INFORMADO" };
			}) || [];

		const scheduledData: number[] = [];
		const canceledData: number[] = [];
		const citizenPresentData: number[] = [];
		const citizenDidNotShowUpData: number[] = [];
		const citizenDidNotWaitData: number[] = [];
		const citizenExcludedData: number[] = [];
		const citizenAttendedData: number[] = [];

		data?.forEach((item) => {
			const scheduledTotal = totalFilteredByStatus(item.values || [], "SCHEDULED");
			const canceledTotal = totalFilteredByStatus(item.values || [], "CANCELED");
			const citizensPresentTotal = totalFilteredByStatus(
				item.values || [],
				"CITIZEN_PRESENT"
			);
			const citizensDidNotShowUpTotal = totalFilteredByStatus(
				item.values || [],
				"DID_NOT_SHOW_UP"
			);
			const citizensDidNotWaitTotal = totalFilteredByStatus(
				item.values || [],
				"DID_NOT_WAIT"
			);
			const citizensExcludedTotal = totalFilteredByStatus(item.values || [], "EXCLUDED");
			const citizensAttendedTotal = totalFilteredByStatus(item.values || [], "ATTENDED");

			scheduledData.push(scheduledTotal);
			canceledData.push(canceledTotal);
			citizenPresentData.push(citizensPresentTotal);
			citizenDidNotShowUpData.push(citizensDidNotShowUpTotal);
			citizenDidNotWaitData.push(citizensDidNotWaitTotal);
			citizenExcludedData.push(citizensExcludedTotal);
			citizenAttendedData.push(citizensAttendedTotal);
		});

		return {
			categories,
			series: [
				{ name: "Agendado", data: scheduledData },
				{ name: "Cancelado", data: canceledData },
				{ name: "Cidadãos presentes", data: citizenPresentData },
				{ name: "Cidadãos não compareceram", data: citizenDidNotShowUpData },
				{ name: "Cidadãos não aguardaram", data: citizenDidNotWaitData },
				{ name: "Cidadãos excluidos", data: citizenExcludedData },
				{ name: "Cidadãos atendidos", data: citizenAttendedData },
			],
		};
	};

	const chartData = formatDataForChart(schedulesAggregatedByHealtUnitAndStatus);

	return (
		<Card>
			<Card.Header>
				<Card.Title>Agendamentos por Unidades</Card.Title>
			</Card.Header>
			<Card.Body className="overflow-auto" style={{ maxHeight: "50rem" }}>
				<BarChart
					categories={chartData.categories.map((tThealthUnit) => tThealthUnit.label)}
					series={chartData.series}
					colors={[
						"#b4b5c2",
						"#f1416c",
						"#04E69A",
						"#E6E6E6",
						"#ffd52f",
						"#7239ea",
						"#008FFB",
					]}
					categoryTextWidth={300}
					dataEvent={(selectedIndex: number) => {
						setSelectedHealthUnit &&
							setSelectedHealthUnit(chartData.categories[selectedIndex].value);
					}}
					isStacked
				/>
			</Card.Body>
		</Card>
	);
}
