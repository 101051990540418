import { get } from "../api";
import { Citizen } from "@/@types/esus/Citizen";
import { Paginated } from "@/@types/generics/paginated";
import { Problem } from "@/@types/esus/Problem";

export type DiseaseResume = {
	recoveredCount: number;
	underTreatmentCount: number;
	untreatedCount: number;
};

export type DiseaseData = {
	firstIndividualRecordDate: string;
	lastIndividualRecordWithDiseaseYesDate: string;
	lastIndividualRecordWithDiseaseNoDate: string;
	citizen: Citizen;
	firstProblemDate: string;
	lastProblemDate: string;
	problems: Problem[];
	currentStatusDisease: boolean;
	status: number;
};

type EpidemicResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
};

type EpidemicParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
	pageNumber: number;
	searchName: string;
	filterStatus: number | null;
	pageSize?: number;
};

export async function getCitizensWithTuberculosisResume({
	uf,
	ibgeCode,
	...params
}: EpidemicResumeParams) {
	const response = await get<DiseaseResume>(
		`/uf/${uf}/city/${ibgeCode}/Epidemic/GetCitizensWithTuberculosisByPeriodResume`,
		params
	);
	return response.data || ({} as DiseaseResume);
}

export async function getCitizensWithTuberculosis({ uf, ibgeCode, ...params }: EpidemicParams) {
	const response = await get<Paginated<DiseaseData>>(
		`/uf/${uf}/city/${ibgeCode}/Epidemic/GetCitizensWithDetailsTuberculosis`,
		params
	);
	return response.data || ({} as Paginated<DiseaseData>);
}

export async function getCitizensWithHansenResume({
	uf,
	ibgeCode,
	...params
}: EpidemicResumeParams) {
	const response = await get<DiseaseResume>(
		`/uf/${uf}/city/${ibgeCode}/Epidemic/GetCitizensWithHansenByPeriodResume`,
		params
	);
	return response.data || ({} as DiseaseResume);
}

export async function getCitizensWithHansen({ uf, ibgeCode, ...params }: EpidemicParams) {
	const response = await get<Paginated<DiseaseData>>(
		`/uf/${uf}/city/${ibgeCode}/Epidemic/GetCitizensWithDetailsHansen`,
		params
	);
	return response.data || ({} as Paginated<DiseaseData>);
}
