import { useState } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";

import { TripleViralResumeType } from "@/services/esus/vaccinationService";

import { TripleViralResumeChart } from "./TripleViralResumeChart";
import { DonutChart } from "@/components/Charts/DonutChart";

type Props = {
	tripleViralResume: TripleViralResumeType;
};

export function TripleViralResumes({ tripleViralResume }: Props) {
	const [tripleViralOption, setTripleViralOption] = useState(2);

	const vaccinated =
		tripleViralOption === 0
			? tripleViralResume.totalD1 || 0
			: tripleViralOption === 1
			? tripleViralResume.totalD2 || 0 + tripleViralResume.totalDU || 0
			: tripleViralResume.finished || 0;

	const notVaccinated =
		(tripleViralResume.identified || 0) -
		(tripleViralOption === 0
			? tripleViralResume.totalD1 || 0
			: tripleViralOption === 1
			? tripleViralResume.totalD2 || 0 + tripleViralResume.totalDU || 0
			: tripleViralResume.finished || 0);

	return (
		<Row className="my-2">
			<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
				<Card className={`h-100`}>
					<Card.Header>
						<Card.Title>Resumo por vacinação</Card.Title>
						<Card.Title>
							<Nav fill variant="tabs" defaultActiveKey="scheme">
								<Nav.Item
									onClick={() => {
										setTripleViralOption(0);
									}}
								>
									<Nav.Link
										eventKey="vaccination-d1"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										D1
									</Nav.Link>
								</Nav.Item>
								<Nav.Item
									onClick={() => {
										setTripleViralOption(1);
									}}
								>
									<Nav.Link
										eventKey="vaccination-d2"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										D2/DU
									</Nav.Link>
								</Nav.Item>
								<Nav.Item
									onClick={() => {
										setTripleViralOption(2);
									}}
								>
									<Nav.Link
										eventKey="scheme"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Esquema
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Title>
					</Card.Header>
					<Card.Body className="d-flex w-100  justify-content-center align-items-center p-0">
						<TripleViralResumeChart
							vaccinated={vaccinated}
							notVaccinated={notVaccinated}
						/>
					</Card.Body>
				</Card>
			</Col>
			<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
				<Card className="h-100">
					<Card.Header>
						<Card.Title>Resumo por status</Card.Title>
					</Card.Header>
					<Card.Body className="d-flex w-100 justify-content-center align-items-center p-0">
						<DonutChart
							width={400}
							series={[
								tripleViralResume.finished || 0,
								tripleViralResume.pending || 0,
								tripleViralResume.delayed || 0,
								tripleViralResume.lost || 0,
								tripleViralResume.abandoned || 0,
							]}
							colors={["#008ffb", "#ffef00", "#feb019", "#ff4560", "#775dd0"]}
							labels={["Concluído", "Pendente", "Em atraso", "Perdido", "Abandono"]}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
